import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
//context
//api
//components
//util
import { colors } from '../util/values';

const AssociationRecipe = ({ recipe, onChangeCallback }) => {
  const { t } = useTranslation();
  const [uniqueId, setUniqueId] = useState(null);
  const [nameCantineChecked, setNameCantineChecked] = useState(false);
  const [nameCantine, setNameCantine] = useState('');
  const [nameChecked, setNameChecked] = useState(false);
  const [name, setName] = useState('');
  const [menusChecked, setMenusChecked] = useState(false);
  const [menus, setMenus] = useState('');
  const [isRecipe, setIsRecipe] = useState(false);
  const [yieldChecked, setYieldChecked] = useState(false);
  const [yieldPortion, setYieldPortion] = useState(null);
  const [yieldKg, setYieldKg] = useState(null);
  const [yieldL, setYieldL] = useState(null);
  const [yieldUnit, setYieldUnit] = useState(null);
  const [typeChecked, setTypeChecked] = useState(false);
  const [type, setType] = useState('');

  useEffect(() => {
    setUniqueId(recipe.uniqueId);
    setNameCantineChecked(recipe.nameCantineChecked ? true : false);
    setNameCantine(recipe.nameCantine);
    setNameChecked(recipe.nameChecked ? true : false);
    setName(recipe.name);
    setMenusChecked(recipe.menusChecked ? true : false);
    setMenus(recipe.menus);
    setIsRecipe(recipe.isRecipe ? true : false);
    setYieldChecked(recipe.yieldChecked ? true : false);
    setYieldPortion(recipe.yield);
    setYieldKg(recipe.yieldKg);
    setYieldL(recipe.yieldL);
    setYieldUnit(recipe.yieldUnit);
    setTypeChecked(recipe.typeChecked ? true : false);
    setType(recipe.type);
  }, []);

  const onChange = (type, value) => {
    if (type === 'nameCantineChecked')
      setNameCantineChecked(value);
    else if (type === 'nameCantine')
      setNameCantine(value);
    else if (type === 'nameChecked')
      setNameChecked(value);
    else if (type === 'name')
      setName(value);
    else if (type === 'menusChecked')
      setMenusChecked(value);
    else if (type === 'menus')
      setMenus(value);
    else if (type === 'isRecipe')
      setIsRecipe(value);
    else if (type === 'yieldChecked')
      setYieldChecked(value);
    else if (type === 'yield')
      setYieldPortion(value);
    else if (type === 'yieldKg')
      setYieldKg(value);
    else if (type === 'yieldL')
      setYieldL(value);
    else if (type === 'yieldUnit')
      setYieldUnit(value);
    else if (type === 'typeChecked')
      setTypeChecked(value);
    else if (type === 'type')
      setType(value);
  };

  const onBlur = (type, value) => {
    onChangeCallback(type, uniqueId, value);
  };

  return (
    <>
      <div style={styles.row}>
        <input style={styles.checkbox} type="checkbox" checked={nameCantineChecked} onChange={event => onChange('nameCantineChecked', event.target.checked)} onBlur={event => onBlur('nameCantineChecked', nameCantineChecked)} />
        <div style={{ marginRight: 20, paddingTop: 15 }}>Nom Recette Client: </div>
        <input style={{ ...styles.textFieldName, width: 800 }} value={nameCantine ? nameCantine : ''} onChange={event => onChange('nameCantine', event.target.value)} onBlur={event => onBlur('nameCantine', nameCantine)} />
      </div>
      <div style={styles.row}>
        <input style={styles.checkbox} type="checkbox" checked={nameChecked} onChange={event => onChange('nameChecked', event.target.checked)} onBlur={event => onBlur('nameChecked', nameChecked)} />
        <div style={{ marginRight: 20, paddingTop: 15 }}>Nom Recette Traiteur: </div>
        <input style={{ ...styles.textFieldName, width: 800 }} value={name ? name : ''} onChange={event => onChange('name', event.target.value)} onBlur={event => onBlur('name', name)} />
      </div>
      <div style={styles.row}>
        <input style={styles.checkbox} type="checkbox" checked={menusChecked} onChange={event => onChange('menusChecked', event.target.checked)} onBlur={event => onBlur('menusChecked', menusChecked)} />
        <div style={{ marginRight: 20, paddingTop: 15 }}>Menus: </div>
        <input style={{ ...styles.textFieldName, width: 800 }} value={menus ? menus : ''} onChange={event => onChange('menus', event.target.value)} onBlur={event => onBlur('menus', menus)} />
      </div>
      <div style={styles.row}>
        <div style={{ marginRight: 10, paddingTop: 0 }}>IsRecipe: </div>
        <input style={styles.checkbox} type="checkbox" checked={isRecipe} onChange={event => onChange('isRecipe', event.target.checked)} onBlur={event => onBlur('isRecipe', isRecipe)} />
      </div>
      <div style={styles.row}>
        <input style={styles.checkbox} type="checkbox" checked={yieldChecked} onChange={event => onChange('yieldChecked', event.target.checked)} onBlur={event => onBlur('yieldChecked', yieldChecked)} />
        <div style={{ marginRight: 20 }}>Portions: </div>
        <input style={{ ...styles.textField, width: 300 }} value={yieldPortion ? yieldPortion : ''} onChange={event => onChange('yield', event.target.value)} onBlur={event => onBlur('yield', yieldPortion)} />
        <div style={{ marginRight: 20 }}>Yield kg: </div>
        <input style={{ ...styles.textField, width: 300 }} value={yieldKg ? yieldKg : ''} onChange={event => onChange('yieldKg', event.target.value)} onBlur={event => onBlur('yieldKg', yieldKg)} />
        <div style={{ marginRight: 20 }}>Yield l: </div>
        <input style={{ ...styles.textField, width: 300 }} value={yieldL ? yieldL : ''} onChange={event => onChange('yieldL', event.target.value)} onBlur={event => onBlur('yieldL', yieldL)} />
        <div style={{ marginRight: 20 }}>Yield unit: </div>
        <input style={{ ...styles.textField, width: 300 }} value={yieldUnit ? yieldUnit : ''} onChange={event => onChange('yieldUnit', event.target.value)} onBlur={event => onBlur('yieldUnit', yieldUnit)} />
      </div>
      <div style={styles.row}>
        <input style={styles.checkbox} type="checkbox" checked={typeChecked} onChange={event => onChange('typeChecked', event.target.checked)} onBlur={event => onBlur('typeChecked', typeChecked)} />
        <div style={{ marginRight: 20 }}>Catégorie: </div>
        <input style={{ ...styles.textField, width: 800 }} value={type ? type : ''} onChange={event => onChange('type', event.target.value)} onBlur={event => onBlur('type', type)} />
      </div>
    </>
  );
};

const styles = {
  checkbox: {
    marginRight: 8,
    width: 18,
    height: 18,
    accentColor: colors.main,
    alignSelf: 'center'
  },
  textFieldName: {
    padding: '4px 0 5px 0',
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 600,
    border: 'none',
    transition: 'border-bottom 50ms cubic-bezier(0.4, 0, 0.2, 1)',
    outline: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.42)',
    backgroundColor: 'transparent'
  },
  textField: {
    padding: '4px 0 5px 0',
    fontFamily: 'Inter',
    fontSize: 16,
    border: 'none',
    transition: 'border-bottom 50ms cubic-bezier(0.4, 0, 0.2, 1)',
    outline: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.42)',
    backgroundColor: 'transparent'
  },
  row: {
    display: 'flex',
    marginBottom: 10,
  },
};

export default AssociationRecipe;