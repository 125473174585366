import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { colors } from '../util/values';
import { useTranslation } from "react-i18next";
import { login } from "../context/AuthService";
import { ButtonFull } from '../components/Buttons';

const LoginScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [values, setValues] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [buttonIsReady, setButtonIsReady] = useState(true);

  const handleInputChange = (event) => {
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const onLogin = async () => {
    setButtonIsReady(false);
    try {
      const response = await login(values.email, values.password);
      if (response && response.data !== 'failed') {
        setError('');
        navigate('/recipes');
        document.location.reload();
      } else {
        setError(t('errorUser'));
      }
      setButtonIsReady(true);
    } catch (error) {
      console.log(error);
      setButtonIsReady(true);
    }
  };

  const onEnterPressed = (key) => {
    if (key === 'Enter') {
      onLogin();
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.page}>
        <div style={styles.header}>
          <img style={styles.logo} src={require('../assets/icon500.png')} />
          <div style={{ marginBottom: 20, color: colors.main, maxWidth: 250, textAlign: 'center' }}>{t("text4")}</div>
          <div style={{ color: colors.main, maxWidth: 250, textAlign: 'center' }}>{t("text63")}</div>
        </div>
        <div style={styles.form}>
          <div style={styles.inputContainer}>
            <div style={styles.label}>{t("lblEmail")}</div>
            <input style={styles.input} type="text" name="email" value={values.email} onChange={handleInputChange} />
          </div>
          <div style={styles.inputContainer}>
            <div style={styles.label}>{t("lblPassword")}</div>
            <input style={styles.input} type="password" name="password" value={values.password} onChange={handleInputChange} onKeyUp={(event) => onEnterPressed(event.key)} />
          </div>
          {(error === '') ? null : <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 20, color: colors.red }}>{error}</div>}
        </div>
        <div style={styles.buttonContainer}>
          <ButtonFull text={t("btnLogin")} onButtonClick={() => onLogin()} disabled={!buttonIsReady} />
        </div>
        <div style={{ padding: 20, maxWidth: 500, textAlign: 'center' }}>
          {t('textCreateAccount')}
          <a href="mailto:info@emissionsreductionnow.com" style={{ color: colors.link, textDecoration: 'none' }} > info@emissionsreductionnow.com</a>
        </div>
        <div style={{ padding: 20, maxWidth: 800, textAlign: 'center', color: colors.main }}>{t('text2')}</div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  page: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    //width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 40
  },
  logo: {
    width: 200
  },
  form: {
    minWidth: 400,
    marginLeft: 20,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  },
  label: {
    color: colors.main,
    marginBottom: 4
  },
  input: {
    padding: 10,
    fontSize: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: colors.main,
    backgroundColor: colors.lightGrey
  },
  buttonContainer: {
    marginBottom: 40,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  button: {
    width: '40%',
    padding: 5,
    fontSize: 18,
    border: 'none',
    borderRadius: 8,
  },
  greenButton: {
    color: 'white',
  },
  whiteButton: {
    color: '#088343',
  }
};

export default LoginScreen;
