import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
//context
//api
import aws from '../api/aws';
//components
import { ButtonFull } from '../components/Buttons';
import AssociationRow from '../components/AssociationRow';
import AssociationBaseIngRow from '../components/AssociationBaseIngRow';
import AssociationRecipe from '../components/AssociationRecipe';
//util
import { colors, completeUnitsWithPortion, massUnits, volumesUnits } from '../util/values';
import { commonStyles } from '../util/styles';
import { sortBy, toLowerNoAccents, getCount, deepCopy, fromAnyToKg2, fromAnyToL2, fromAnyToUnit2, fromAnyToPortions2 } from '../util/climatecook';

const Associations = () => {
  const { t } = useTranslation();
  const [recipes, setRecipes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [baseIngs, setBaseIngs] = useState([]);
  const [traiteurs, setTraiteurs] = useState([]);
  const [ownerStr, setOwnerStr] = useState('');
  const [vendorStr, setVendorStr] = useState('');
  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getTraiteurs();
  }, []);

  const getTraiteurs = async () => {
    const response = await aws.get('/user', { params: { type: 'getTraiteurs' } });
    if (response.data) {
      setTraiteurs(response.data);
    }
    getDbIngs();
  };

  const getDbIngs = async () => {
    setIsReady(false);
    let cursor = null;
    let completeResponse = [];
    do {
      console.log('boucle', cursor);
      const response = await aws.get('/association', { params: { type: 'everything', cursor } });
      if (response.data) {
        console.log(response.data);
        completeResponse = [...completeResponse, ...response.data.ings];
        cursor = response.data.cursor;
      }
    } while (cursor != null);

    let newIngs = [];
    for (let i = 0; i < completeResponse.length; i++) {
      if (completeResponse[i].ing_level === 0) {
        newIngs.push(getIng(completeResponse, completeResponse[i]));
      }
    }
    newIngs = newIngs.sort(sortBy('nom', true));
    newIngs.sort((a, b) => a.owner === 'ERN' && b.owner !== 'ERN' ? -1 : (a.owner !== 'ERN' && b.owner === 'ERN' ? 1 : 0));
    setIngredients(newIngs);
    setIsReady(true);
  };

  const getIng = (itemList, mainItem) => {
    let traiteur = traiteurs.find(t => t.user_id === mainItem.vendor);
    let ing = {
      id: mainItem.ingredient_id,
      name: mainItem.name_fra,
      nom: mainItem.name_fra + (traiteur ? ' - ' + traiteur.name : '') + ' - ' + (mainItem.owner === null ? 'ERN' : mainItem.owner),
      unit: mainItem.unit,
      quantity: mainItem.quantity,
      yieldKg: mainItem.yield_kg,
      yieldL: mainItem.yield_l,
      yieldUnit: mainItem.yield_unit,
      yieldPortion: mainItem.portions,
      owner: mainItem.owner === null ? 'ERN' : mainItem.owner,
      vendor: mainItem.vendor,
      composition: [],
      isAutoKg: false,
      isAutoL: false,
      isAutoUnit: false,
      isAutoPortions: false,
    };
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].main_ingredient_id !== null && itemList[i].main_ingredient_id === mainItem.ingredient_id) {
        ing.composition.push(getIng(itemList, itemList[i]));
      }
    }
    if (ing.composition.length === 1) {
      if (ing.yieldKg === -1) {
        ing.yieldKg = fromAnyToKg2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit, ing.composition[0].yieldPortion);
        ing.isAutoKg = true;
      }
      if (ing.yieldL === -1) {
        ing.yieldL = fromAnyToL2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit, ing.composition[0].yieldPortion);
        ing.isAutoL = true;
      }
      if (ing.yieldUnit === -1) {
        ing.yieldUnit = fromAnyToUnit2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit, ing.composition[0].yieldPortion);
        ing.isAutoUnit = true;
      }
      if (ing.yieldPortion === -1) {
        ing.yieldPortion = fromAnyToPortions2(ing.composition[0].unit, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit, ing.composition[0].yieldPortion);
        ing.isAutoPortions = true;
      }
    }
    return ing;
  };

  const getIng2 = (itemList, mainItem) => {
    let traiteur = traiteurs.find(t => t.user_id === mainItem.vendor);
    let ing = {
      id: mainItem.ingredient_id,
      name: mainItem.name_fra,
      nom: mainItem.name_fra + (traiteur ? ' - ' + traiteur.name : '') + ' - ' + (mainItem.owner === null ? 'ERN' : mainItem.owner),
      unit: mainItem.unit,
      quantity: mainItem.quantity,
      yieldKg: mainItem.yield_kg,
      yieldL: mainItem.yield_l,
      yieldUnit: mainItem.yield_unit,
      yieldPortion: mainItem.portions,
      owner: mainItem.owner === null ? 'ERN' : mainItem.owner,
      vendor: mainItem.vendor,
      composition: []
    };

    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].main_ingredient_id !== null && itemList[i].main_ingredient_id === mainItem.ingredient_id) {
        ing.composition.push(getIng(itemList, itemList[i]));
      }
    }
    return ing;
  };

  const onImport = async (url) => {
    if (url != null) {
      const response = await fetch(URL.createObjectURL(url));
      let json = await response.json();
      let baseIngsJson = [];
      let recipesJson = [];
      if (json instanceof Array) {
        recipesJson = json;
      } else {
        recipesJson = json.recipes;
        baseIngsJson = json.baseIngs;
        setOwnerStr(json.owner);
        setVendorStr(json.vendor);
      }
      json = null;
      let newRecipesJson = [];
      recipesJson.forEach(item => {
        let newItem = {};
        newItem.uniqueId = getCount();
        newItem.nameCantine = item.nameCantine;
        newItem.nameCantineChecked = item.nameCantineChecked;
        newItem.name = item.name;
        newItem.nameChecked = item.nameChecked;
        newItem.type = item.type;
        newItem.typeChecked = item.typeChecked;
        newItem.vendor = item.vendor;
        newItem.ownerChecked = item.ownerChecked;
        newItem.owner = item.owner;
        newItem.menusChecked = item.menusChecked;
        newItem.menus = item.menus;
        newItem.isRecipeChecked = item.isRecipeChecked;
        newItem.isRecipe = item.isRecipe;
        newItem.raw = item.raw;
        newItem.yield = item.yield;
        newItem.yieldKg = item.yieldKg;
        newItem.yieldL = item.yieldL;
        newItem.yieldUnit = item.yieldUnit;
        newItem.yieldChecked = item.yieldChecked;
        newItem.insts = item.insts;

        let newIngs = [];
        item.ings.forEach(i => {
          let newIng = {};
          newIng.checked = i.checked;
          newIng.name = i.name;
          newIng.qty = i.qty;
          newIng.step = i.step;
          let u = getUnit(i.unit);
          newIng.unit = u ? u.name : 'unit';
          newIng.assoChecked = i.assoChecked;
          newIng.association = i.association;
          newIng.associationName = i.associationName;
          newIng.associationId = i.associationId;
          newIng.associationOwner = i.associationOwner;
          newIng.yieldAssoKg = i.yieldAssoKg;
          newIng.yieldAssoL = i.yieldAssoL;
          newIng.yieldAssoUnit = i.yieldAssoUnit;
          newIng.yieldAssoPortion = i.yieldAssoPortion;
          newIng.which = i.which;
          newIng.unitCompatible = i.unitCompatible;
          newIng.clar = i.clar;
          newIng.alt = i.alt;
          newIng.all = i.all;
          newIng.uniqueId = getCount();
          newIngs.push(newIng);
        });

        newItem.ings = newIngs;
        newIngs.push({ checked: false, which: null, name: null, qty: null, step: null, unit: null, clar: null, alt: null, uniqueId: getCount() });
        newIngs.push({ checked: false, which: null, name: null, qty: null, step: null, unit: null, clar: null, alt: null, uniqueId: getCount() });
        newIngs.push({ checked: false, which: null, name: null, qty: null, step: null, unit: null, clar: null, alt: null, uniqueId: getCount() });
        newIngs.push({ checked: false, which: null, name: null, qty: null, step: null, unit: null, clar: null, alt: null, uniqueId: getCount() });
        newRecipesJson.push(newItem);
      });

      let newBaseIngsJson = [];
      baseIngsJson.forEach(item => {
        let newItem = {};
        newItem.uniqueId = getCount();
        newItem.checked = item.checked;
        newItem.mainUnit = item.mainUnit;
        newItem.name = item.name;
        newItem.yieldKg = item.yieldKg;
        newItem.yieldL = item.yieldL;
        newItem.yieldUnit = item.yieldUnit;
        newItem.yieldPortion = item.yieldPortion;
        newItem.associationId = item.associationId;
        newItem.associationName = item.associationName;
        newItem.isConflict = item.isConflict;
        newBaseIngsJson.push(newItem);
      });

      setRecipes(newRecipesJson);
      setBaseIngs(newBaseIngsJson);
    }
    document.getElementById("uploadIngsButton").value = "";
  };

  const associate = () => {
    let ings = [];
    recipes.forEach(r => {
      r.ings.forEach(i => {
        if (i.name != null && i.name !== '' && i.checked && i.associationId == null && (i.association == null || i.association === '')) {
          ings.push({
            checked: i.checked, qty: i.quantity, unit: i.unit, name: i.name, assoChecked: i.assoChecked, association: i.associationName,
            associationId: i.associationId, associationOwner: i.associationOwner, which: i.which, clar: i.clar, alt: i.alt, step: i.step, uniqueId: i.uniqueId
          });
        }
      });
    });
    let randomId = Math.floor(Math.random() * 10000);
    let response = aws.post('/association', { id: randomId, recipes: ings });
    console.log('startInterval');
    let count = 0;
    let myInt = setInterval(async () => {
      count = count + 1;
      if (count <= 60) {
        try {
          let response2 = await aws.get('/temp', { params: { id: randomId } });
          if (response2.data.state === 'wait') {
            console.log('wait', count);
          } else if (response2.data.state === 'done') {
            let objs = JSON.parse(response2.data.res);
            console.log('done', count, objs);

            let newRecipes = [...recipes];
            newRecipes.forEach(r => {
              r.ings.forEach(i => {
                let found = objs.find(o => o.uniqueId === i.uniqueId);
                if (found) {
                  i.association = found.association;
                  i.associationName = found.association;
                  i.associationId = found.associationId;
                  i.associationOwner = found.associationOwner;
                  i.which = found.which;
                  let u = getUnit(found.unit);
                  let unitComp = u && ((massUnits[u.name] && found.yieldKg !== null) || (volumesUnits[u.name] && found.yieldL !== null) || (u.name === 'unit' && found.yieldUnit !== null) || (u.name === 'portions' && found.yieldPortion !== 0));
                  i.unitCompatible = unitComp;
                  i.yieldAssoKg = found.yieldKg;
                  i.yieldAssoL = found.yieldL;
                  i.yieldAssoUnit = found.yieldUnit;
                  i.yieldAssoPortion = found.yieldPortion;
                  return true;
                }
              });
            });
            setRecipes(newRecipes);
            clearInterval(myInt);
          } else {
            clearInterval(myInt);
          }
        } catch (e) {
          console.log(e);
          clearInterval(myInt);
        }
      } else {
        clearInterval(myInt);
      }
    }, 10000);
  };

  const readyToDl = () => {
    let newRecipes = [...recipes];
    //let newRecipes = JSON.parse(JSON.stringify(recipes));
    newRecipes.forEach(r => {
      r.ings = r.ings.filter(i => i.name !== null && i.name !== '');
    });
    let json = {
      owner: ownerStr,
      vendor: vendorStr,
      recipes: newRecipes,
      baseIngs: baseIngs,
    };
    return json;
  };

  const downloadList = () => {
    let json = readyToDl();
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "recipes.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const importToDb = async () => {
    setShowModal(false);
    let json = readyToDl();
    const response = await aws.post('/import', { all: json });
    console.log(response);
  };

  const getUnit = (unit) => {
    if (unit == null)
      return false;
    for (let c of completeUnitsWithPortion) {
      if (c.others.includes(toLowerNoAccents(unit).replaceAll(' ', ''))) {
        return c;
      }
    }
    return false;
  };

  const onChangeRecipe = async (key, uniqueId, value) => {
    let newRecipes = [...recipes];
    let found = newRecipes.find(r => r.uniqueId === uniqueId);
    if (found) {
      found[key] = value;
      setRecipes(newRecipes);
    }
  };

  const renderList = (list) => {
    return (
      <div>
        {list.map(recipe => {
          return (<div key={'rec' + recipe.uniqueId} style={{ marginTop: 40 }} className='recipe'>
            <AssociationRecipe recipe={recipe} onChangeCallback={onChangeRecipe} />
            {renderIngredients(recipe.ings)}
          </div>
          );
        })}
      </div>
    );
  };

  const changeCallback = (key, id, value) => {
    let newRecipes = [...recipes];
    newRecipes.some(r => {
      let ing = r.ings.find(o => o.uniqueId === id);
      if (ing) {
        if (key === 'associationInput') {
          ing.association = value;
          ing.associationName = undefined;
          ing.associationId = undefined;
          ing.associationOwner = undefined;
          ing.yieldAssoKg = undefined;
          ing.yieldAssoL = undefined;
          ing.yieldAssoUnit = undefined;
          ing.yieldAssoPortion = undefined;
          ing.which = undefined;
          ing.unitCompatible = undefined;
        } else if (key === 'associationObject') {
          ing.association = value.nom;
          ing.associationName = value.name;
          ing.associationId = value.id;
          ing.associationOwner = value.owner;
          ing.yieldAssoKg = value.yieldKg;
          ing.yieldAssoL = value.yieldL;
          ing.yieldAssoUnit = value.yieldUnit;
          ing.yieldAssoPortion = value.yieldPortion;
          ing.which = value.which;
          ing.unitCompatible = value.unitCompatible;
        } else if (key === 'unit') {
          ing.unit = value.unit.name;
          ing.unitCompatible = value.compat;
        } else if (key === 'assoChecked') {
          ing[key] = value;
          if (value) {
            onAssoChecked(ing);
          }
        } else {
          ing[key] = value;
        }
        return true;
      }
    });

    setRecipes(newRecipes);
  };

  const onAssoChecked = (ing) => {
    if (ing.name == null || ing.name === '')
      return;

    let newBaseIngs = [...baseIngs];
    if (toLowerNoAccents(ing.name) !== toLowerNoAccents(ing.associationName)) {
      addBaseIng(ing.name, ing.unit, ing.yieldAssoKg, ing.yieldAssoL, ing.yieldAssoUnit, ing.yieldAssoPortion, ing.associationId, ing.associationName, ing.unitCompatible, newBaseIngs);
    }
    let newRecipes = [...recipes];
    //let newRecipes = deepCopy(JSON.parse(JSON.stringify([...recipes])));
    newRecipes.forEach(r => {
      r.ings.forEach(i => {
        if (i.checked && i.name != null && toLowerNoAccents(i.name) === toLowerNoAccents(ing.name) && !i.assoChecked/* && isSameUnitType(getUnit(ing.unit), getUnit(i.unit))*/) {
          i.assoChecked = true;
          i.association = ing.association;
          i.associationName = ing.associationName;
          i.associationId = ing.associationId;
          i.associationOwner = ing.associationOwner;
          i.yieldAssoKg = ing.yieldAssoKg;
          i.yieldAssoL = ing.yieldAssoL;
          i.yieldAssoUnit = ing.yieldAssoUnit;
          i.yieldAssoPortion = ing.yieldAssoPortion;
          i.which = ing.which;
          let u = getUnit(i.unit);
          let newIsUnitCompatible = u && ((massUnits[u.name] && ing.yieldAssoKg !== null) || (volumesUnits[u.name] && ing.yieldAssoL !== null) || (u.name === 'unit' && ing.yieldAssoUnit !== null) || (u.name === 'portions' && ing.yieldAssoPortion !== 0));
          i.unitCompatible = newIsUnitCompatible;

          if (toLowerNoAccents(ing.name) !== toLowerNoAccents(ing.associationName)) {
            addBaseIng(i.name, i.unit, ing.yieldAssoKg, ing.yieldAssoL, ing.yieldAssoUnit, ing.yieldAssoPortion, ing.associationId, ing.associationName, i.unitCompatible, newBaseIngs);
          }
        }
      });
    });
    setRecipes(newRecipes);
    setBaseIngs(newBaseIngs);
  };

  const addBaseIng = (ingName, ingUnit, yieldAssoKg, yieldAssoL, yieldAssoUnit, yieldAssoPortion, ingAssociationId, ingAssociation, unitCompatible, newBaseIngs) => {
    const found = newBaseIngs.find(b => toLowerNoAccents(b.name) === toLowerNoAccents(ingName));
    if (found) {
      let unitName = getUnit(ingUnit).name;
      if (found.yieldKg === '' && massUnits[unitName] && (yieldAssoKg != null || !unitCompatible)) {
        found.yieldKg = unitCompatible ? yieldAssoKg : 0;
        found.isConflict = true;
      } else if (found.yieldL === '' && volumesUnits[unitName] && (yieldAssoL != null || !unitCompatible)) {
        found.yieldL = unitCompatible ? yieldAssoL : 0;
        found.isConflict = true;
      } else if (found.yieldUnit === '' && unitName === 'unit' && (yieldAssoUnit != null || !unitCompatible)) {
        found.yieldUnit = unitCompatible ? yieldAssoUnit : 0;
        found.isConflict = true;
      } else if (found.yieldPortion === '' && unitName === 'portions' && (yieldAssoPortion != 0 || !unitCompatible)) {
        found.yieldPortion = yieldAssoPortion;
        found.isConflict = true;
      }
    } else {
      let yKg = '';
      let yL = '';
      let yUnit = '';
      let yPortion = '';
      let unitName = getUnit(ingUnit).name;
      if (massUnits[unitName] && (yieldAssoKg != null || !unitCompatible))
        yKg = unitCompatible ? yieldAssoKg : 0;
      else if (volumesUnits[unitName] && (yieldAssoL != null || !unitCompatible))
        yL = unitCompatible ? yieldAssoL : 0;
      else if (unitName === 'unit' && (yieldAssoUnit != null || !unitCompatible))
        yUnit = unitCompatible ? yieldAssoUnit : 0;
      else if (unitName === 'portions' && (yieldAssoPortion !== 0 || !unitCompatible))
        yPortion = unitCompatible ? yieldAssoPortion : 0;
      newBaseIngs.push({ uniqueId: getCount(), checked: false, name: ingName, mainUnit: ingUnit, yieldKg: yKg, yieldL: yL, yieldUnit: yUnit, yieldPortion: yPortion, associationId: ingAssociationId, associationName: ingAssociation, isConflict: false });
    }
  };

  const isSameUnitType = (unit1, unit2) => {
    return completeUnitsWithPortion.find(o => o.id === unit1.id).category === completeUnitsWithPortion.find(o => o.id === unit2.id).category;
  };

  const renderIngredients = (items) => {
    return (
      <table>
        <tbody>
          <tr>
            <th>
              <div style={styles.header}></div>
            </th>
            <th>
              <div style={styles.header}>qty</div>
            </th>
            <th>
              <div style={styles.header}>unit</div>
            </th>
            <th>
              <div style={styles.header}>ingredient</div>
            </th>
            <th></th>
            <th>
              <div style={styles.header}>association</div>
            </th>
            <th>
              <div style={styles.header}>id</div>
            </th>
            <th>
              <div style={styles.header}>owner</div>
            </th>
            <th>
              <div style={styles.header}>compatible</div>
            </th>
            <th>
              <div style={styles.header}>etape</div>
            </th>
            <th>
            </th>
            <th>
              <div style={styles.header}>clarification</div>
            </th>
            <th>
              <div style={styles.header}>alternative</div>
            </th>
            <th>
              <div style={styles.header}>step</div>
            </th>
            <th>
              <div style={styles.header}>raw ingredient</div>
            </th>
          </tr>
          {items.map(item => {
            return (<AssociationRow key={'ing' + item.uniqueId} association={item} autoComplete={ingredients} onChangeCallback={changeCallback} />);
          })}
        </tbody>
      </table>
    );
  };

  const changeCallbackBaseIng = (key, uniqueId, value) => {
    let newBaseIngs = [...baseIngs];
    let obj = newBaseIngs.find(o => o.uniqueId === uniqueId);
    if (key === 'checked' && value)
      obj.isConflict = false;
    obj[key] = value;

    setBaseIngs(newBaseIngs);
  };

  const renderList2 = (list) => {
    return (
      <table>
        <tbody>
          {list.length > 0 ? <tr>
            <th>
              <div style={styles.header}></div>
            </th>
            <th>
              <div style={styles.header}>ing client</div>
            </th>
            <th>
              <div style={styles.header}>yield kg</div>
            </th>
            <th>
              <div style={styles.header}>yield l</div>
            </th>
            <th>
              <div style={styles.header}>yield unit</div>
            </th>
            <th>
              <div style={styles.header}>yield portion</div>
            </th>
            <th>
              <div style={styles.header}>ern ing id</div>
            </th>
            <th>
              <div style={styles.header}>ern ing name</div>
            </th>
          </tr> : null}
          {list.map(item => {
            return (<AssociationBaseIngRow key={"baseIng" + item.uniqueId} association={item} onChangeCallback={changeCallbackBaseIng} />);
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div style={commonStyles.container}>
      {showModal ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('Êtes vous sur d\'avoir tout bien vérifié')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnNo')} onButtonClick={() => setShowModal(false)} />
            <ButtonFull text={t('btnYes')} onButtonClick={() => importToDb()} />
          </div>
        </div>
      </div> : null}
      <div style={{}}>
        <div style={styles.options}>
          <input id="uploadIngsButton" type="file" accept="application/json" onChange={(event) => { onImport(event.target.files[0]) }} hidden />
          <ButtonFull text={t('Choisir un fichier')} onButtonClick={() => document.getElementById("uploadIngsButton").click()} />
          <div style={{ width: 20 }}></div>
          <ButtonFull text={t('Rafraichir la bd')} onButtonClick={() => getDbIngs()} />
          <div style={{ width: 20 }}></div>
          <ButtonFull text={t('Sauvegarder (Exporter)')} onButtonClick={() => downloadList()} />
          <div style={{ width: 20 }}></div>
          <ButtonFull text={t('Associer')} onButtonClick={() => associate()} />
          <div style={{ width: 20 }}></div>
          <ButtonFull text={t('Importer')} onButtonClick={() => setShowModal(true)} />
        </div>
        <div style={{ backgroundColor: '#e1ffd4' }}>1 = Match avec ceux qui ont exactement le meme nom (owner ERN)</div>
        <div style={{ backgroundColor: '#e1ffd4' }}>2 = Match avec ce qui a déjà était matché, doit être en 1 pour 1 avec un ingrédient d'ERN)</div>
        <div style={{ backgroundColor: '#e1ffd4' }}>3 = Match avec ce qui existe (malgré que c'est pas 1 pour 1)</div>
        <div style={{ backgroundColor: '#d4edff' }}>4 = Match chat GPT 95% et +</div>
        <div style={{ backgroundColor: '#ffeed4' }}>5 = Match chat GPT 80% a 95%</div>
        <div style={{ backgroundColor: '#ffd4d4' }}>6 = Match chat GPT - de 80%</div>
        <div style={{ backgroundColor: '#e1ffd4' }}>7 = Match fait manuellement</div>
        <div style={{ height: 20 }}></div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div>Client:</div>
          <input style={{ ...styles.textField, width: 300, marginLeft: 20 }} value={ownerStr} onChange={event => setOwnerStr(event.target.value)} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ height: 10 }}></div>
          <div>Traiteur:</div>
          <input style={{ ...styles.textField, width: 300, marginLeft: 20 }} value={vendorStr} onChange={event => setVendorStr(event.target.value)} />
        </div>
        {renderList(recipes)}
        <div style={{ height: 180 }}></div>
        {renderList2(baseIngs)}
        <div style={{ height: 40 }}></div>
      </div>
    </div>
  );

};

const styles = {
  options: {
    display: 'flex',
    margin: 20
  },
  list: {

  },
  row: {
    display: 'flex',
    marginBottom: 10,
  },
  textField: {
    padding: '4px 0 5px 0',
    fontFamily: 'Inter',
    fontSize: 16,
    border: 'none',
    transition: 'border-bottom 50ms cubic-bezier(0.4, 0, 0.2, 1)',
    outline: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.42)',
    backgroundColor: 'transparent'
  },
  header: {
    textAlign: 'left'
  },


  modalWindow: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 200,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 20,
    textAlign: 'center'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },
};

export default Associations;