import React, { useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
//util
import { colors } from '../util/values';

export const OrderArrows = ({ id, name, stateType, stateIsAsc }) => {
  //0 = none, 1 = ascending, 2 = descending
  const [state, setState] = useState(0);

  useEffect(() => {
    setState(stateType === id ? (stateIsAsc ? 1 : 2) : 0);
  }, [stateType, stateIsAsc]);

  return (
    <div style={styles.container}>
      <div>{name}</div>
      <div style={styles.arrows}>
        <MdExpandLess size={16} color={state === 1 ? colors.main : colors.grey} />
        <MdExpandMore size={16} color={state === 2 ? colors.main : colors.grey} />
      </div>
    </div>

  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  arrows: {
    marginLeft: 6,
    display: 'flex',
    flexDirection: 'column',
  }
};