import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
//components
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//util
import { colors } from '../util/values';
import { getCount } from '../util/climatecook';
import { commonStyles } from '../util/styles';

const InfosScreen = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const user = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [hover, setHover] = useState('');
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');

  useEffect(() => {
    getCategories();
    if (user[0].user_id === 'C38142')
      setText1(`L’organisme de bienfaisance ERN travaille avec le service alimentaire du CHU Sainte-Justine afin de l'aider dans sa démarche de transition écologique. C’est dans le cadre d’un projet pilote réalisé en 2023 qu’ERN adapte son application mobile Climat en Chef, un logiciel permettant d’estimer l’empreinte carbone de divers choix alimentaires quotidiens, afin qu’elle réponde au besoin des services de restauration. Le CHU Sainte-Justine est l’une des 3 entités participantes à ce projet pilote, au même titre que Lola Rosa et Arhoma. Grâce à cette participation et en se servant de l’application Climat en Chef Restauration, le service alimentaire Délipapilles du CHU Sainte-Justine a estimé l’empreinte carbone de l’offre de sa cafétéria. Outillé de ces informations, Délipapilles pourra poursuivre la bonification de son offre et pourra communiquer les bénéfices auprès de ses clientèles afin d’améliorer l’impact de son offre sur le climat. Le soutien de l’ensemble de la communauté est bienvenu afin de faire de cette démarche une réussite.
Vous avez peut-être des commentaires, des questions ou un avis concernant le projet pilote. Si c’est le cas, nous vous invitons à nous les partager en remplissant ce [formulaire](https://docs.google.com/forms/d/e/1FAIpQLScx49c7VzNVRll6_8BQIrw_0X62RNCKl1_EIDiAEQq8JfvJEQ/viewform).`);
    else if (user[0].user_id === 'A5733')
      setText1(`L’organisme de bienfaisance ERN travaille avec Arhoma afin de l'aider dans sa démarche de transition alimentaire. C’est dans le cadre d’un projet pilote réalisé en 2023 qu’ERN adapte son application mobile Climat en Chef, un logiciel permettant d’estimer l’empreinte carbone de divers choix alimentaires quotidiens, afin qu’elle réponde au besoin des services de la restauration. Arhoma est l’une des 3 entités participantes à ce projet pilote, au même titre que Lola Rosa et le CHU Sainte-Justine. Grâce à cette participation et en se servant de l’application Climat en Chef - Restauration, Arhoma a estimé l’empreinte carbone d'une grande partie de son offre. Outillé de ces informations, Arhoma pourra poursuivre la bonification de son offre. Le soutien de l’ensemble de la communauté est bienvenu afin de faire de cette démarche une réussite.
Vous avez peut-être des commentaires, des questions ou un avis concernant le projet pilote. Si c’est le cas, nous vous invitons à nous les partager en remplissant ce [formulaire](https://docs.google.com/forms/d/e/1FAIpQLScx49c7VzNVRll6_8BQIrw_0X62RNCKl1_EIDiAEQq8JfvJEQ/viewform).`);
    setText2(`L’empreinte carbone est un indicateur d’impact environnemental mesurant les émissions de gaz à effet de serre (GES) liées à des activités. L’empreinte carbone est exprimée en dioxyde de carbone équivalent CO2e, une mesure tenant compte de l’impact de tous les GES selon leur potentiel de réchauffement climatique respectif.
Selon le [Rapport 2019](https://wedocs.unep.org/bitstream/handle/20.500.11822/30798/EGR19ESFR.pdf?sequence=15) sur l’écart entre les besoins et les perspectives en matière de réduction des émissions du programme des Nations unies pour l’environnement (ONU), l’humanité ne devrait pas émettre plus de 25 milliards de tonnes CO2e par an d’ici 2030 pour avoir 66% de chance de maintenir l’augmentation de la température à 1.5oC.
Or selon l’ONU, nous serons [8.5 milliards d’humains en 2030](https://www.un.org/fr/global-issues/population).
Ainsi, si nous divisons 25 milliards de tonne CO2e par 8.5 milliards d’humains, nous obtenons un budget total de 2.94 tonnes CO2e par personne et par an.
Considérant que la production d’aliments est responsable d’[environ 1/3 des émissions de GES mondiales](https://www.nature.com/articles/s43016-021-00225-9) nous pouvons suggérer un budget carbone annuel pour l’alimentation de 1 tonne de CO2e par personne.
Toutefois, selon plusieurs sources, l’empreinte alimentaire des [québécois.es](https://unpointcinq.ca/comprendre/empreinte-carbone-de-notre-alimentation/) et des [américains.nes](https://shrinkthatfootprint.com/food-carbon-footprint-diet) avoisine plutôt 2.5 tonnes CO2e par an et par personne.
Ainsi, si nous voulons faire mieux et atteindre les objectifs de réduction de GES, nous pouvons établir une cible quotidienne du budget alimentaire annuelle:
Si nous divisons 1 tonne CO2e par 365.25 jours dans une année, nous obtenons une cible quotidienne de 2.74 kgCO2e par jour.
En nous basant sur cette cible quotidienne, nous avons dérivé des cibles par catégories de consommation alimentaire et établi des seuils d’empreinte faible et d’empreinte élevée. Le seuil d’empreinte élevée est systématiquement environ 50% plus élevé que le seuil d’empreinte faible.
Puisque nous consommons approximativement [1.78 kg](https://www.ledevoir.com/documents/special/22-03-assiette-un-an/index.html) de nourriture par jour, un ingrédient ayant une empreinte de 1.54 kgCO2e par kg d’aliment est considéré comme ayant une empreinte faible. Toutefois, un ingrédient ayant une empreinte de 2.3 kgCO2e par kg d’aliment est considéré comme ayant une empreinte élevée.
Ex. Pour un plat principal, nous avons émis l’hypothèse qu’il [représente 30%](https://files.wri.org/d8/s3fs-public/2022-06/identifying-cool-food-meals-2022-update.pdf?VersionId=v_mgsuG9j6apT3IYgpP49xkrUW1Pf8w9) de la consommation quotidienne et devrait donc avoir un seuil de 0.3 x 2.74, soit environ 0.8 kgCO2e, sous lequel l’empreinte peut-être considérée comme faible.
Veuillez noter que tous les seuils des recettes dans l’application mobile Climat en Chef – Grand Public sont évaluées en se servant de la catégorie ‘plat principal’.
Il faut garder en tête que d’un plat, d’une entrée, d’un ingrédient, etc. à l’autre, l’apport nutritionnel peut être très différent. C’est une des limitations de l’approche que nous avons retenue étant donné que l’objectif est d’offrir une alimentation saine et faible en carbone. ERN envisage donc d’intégrer éventuellement des considérations nutritionnelles dans son évaluation de ce que représente une offre alimentaire faible en carbone.`);
    setText3(`L’approche d’estimation de l’empreinte carbone alimentaire que nous utilisons repose sur l’analyse du cycle de vie (ACV). L’ACV est une méthode qui évalue l’ensemble des impacts environnementaux d’un produit ou d’un processus tout au long de son cycle de vie, de la production à la consommation, en passant par la distribution et la fin de vie.
Il faut savoir que certaines étapes du cycle de vie de l’alimentation ont plus d’impact sur le climat que d’autres. Effectivement: ”Ensemble, l’utilisation des terres et les émissions à l’exploitation agricole représentent plus de 80 % de l’empreinte de la plupart des aliments. Les transports contribuent peu aux émissions. Pour la plupart des produits alimentaires, elle représente moins de 10 %, et elle est bien moindre pour les plus gros émetteurs de GES. Dans le bœuf provenant de troupeaux de bovins, c’est 0,5 %. Pas seulement le transport, mais tous les processus de la chaîne d’approvisionnement après que les aliments ont quitté l’exploitation agricole – transformation, transport, vente au détail et emballage – représentent pour l’essentiel une petite part des émissions.” [Source Our Wold in Data](https://ourworldindata-org.translate.goog/food-choice-vs-eating-local?_x_tr_sl=en&_x_tr_tl=fr&_x_tr_hl=fr&_x_tr_pto=wapp)
Ainsi, bien qu’il n’existe pas encore de bases de données complètes permettant d’estimer l’empreinte carbone de tous les aliments dans leur moindre différence, il est possible de se servir de diverses sources et techniques afin de fournir des estimations. L’objectif est de munir nos clients de pistes de solution afin de réduire l’empreinte carbone de leurs choix. Pour se faire, nous nous assurons d’utiliser uniquement des sources réputées et qui comprennent les étapes de cycle de vie ayant le plus d’impact sur le climat.
ERN s’appuie donc sur diverses bases de données réputées, comme celle d’Agribalyse (France) ou encore d’Eaternity (Suisse), qui comprennent les étapes ayant le plus d’impact sur l’empreinte carbone des ingrédients, comme la production.
Se faisant, nos logiciels peuvent estimer l’empreinte carbone des menus, des approvisionnements, des recettes, des ventes, etc. offrant ainsi aux utilisateurs une compréhension globale de leurs émissions de GES alimentaires.`);
  }, []);

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  const getCategories = async () => {
    const response = await aws.get('/categories/thresholds', { params: { owner: user[0].user_id } });
    if (response.data) {
      let newRows = [];

      for (let i = 0; i < response.data.length; i++) {
        newRows.push({
          category: response.data[i].name_fr != null ? response.data[i].name_fr : '',
          thresholdLow: response.data[i].threshold_low,
          thresholdHigh: response.data[i].threshold_high,
          hypothese: response.data[i].hypothese != null ? response.data[i].hypothese : '',
          color: i % 2 === 0 ? "#fafafa" : colors.white
        });
      }
      setRows(newRows);
    }
  };

  const renderLink = (url, text) => {
    return renderLink2(url, text, getCount());
  };
  const renderLink2 = (url, text, counter) => {
    return <a key={counter} style={hover === url ? styles.linkHover : styles.link}
      onMouseEnter={() => handleMouseEnter(url)}
      onMouseLeave={() => handleMouseLeave("")}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >{text}</a>;
  };

  const renderRows = (items) => {
    return items.map(item =>
      <tr key={item.category} style={{ backgroundColor: item.color }}>
        <td style={styles.cell}>{item.category}</td>
        {item.thresholdLow != null ? <td style={styles.cell}>{item.thresholdLow}<span style={styles.co2}> kgCO<sub>2</sub>e</span></td> : <td style={styles.cell}></td>}
        {item.thresholdHigh != null ? <td style={styles.cell}>{item.thresholdHigh}<span style={styles.co2}> kgCO<sub>2</sub>e</span></td> : <td style={styles.cell}></td>}
        <td style={styles.cell}>{item.hypothese}</td>
      </tr>
    );
  };

  const formatText = (text) => {
    let newText = [];
    let linkRegex = /\[(.+?)\]\((.+?)\)/g;
    let linkArray = [...text.matchAll(linkRegex)];
    text = text.replaceAll(linkRegex, '${split}link${split}');
    text = text.replaceAll('CO2e', '${split}co2e${split}');
    text = text.replaceAll('\n', '${split}br${split}${split}br${split}');

    let linksPointer = 0;
    let temp = text.split(/\${split}/);
    newText = temp.map(t => {
      if (t === 'link') {
        let res = renderLink(linkArray[linksPointer][2], linkArray[linksPointer][1], getCount());
        linksPointer++;
        return res;
      } else if (t === 'co2e')
        return <span key={getCount()}>CO<sub style={styles.sub}>2</sub>e</span>;
      else if (t === 'br')
        return <br key={getCount()}></br>;
      else
        return t;
    });
    return newText;
  };

  const renderCategories = () => {
    if (user[0].user_id === 'C38142') {
      return (
        <div style={styles.section}>
          <p>Ainsi, vous trouverez des pastilles de couleurs indiquant l’empreinte carbone estimée du mets ainsi que la plage d’empreinte carbone à laquelle le mets principal appartient. Voir ci-dessous pour une représentation visuelle de ces plages d’empreinte carbone faible, moyenne et élevée des mets principaux de la cafétéria le Délipapilles.</p>
          <img src={require('../assets/Ex.-seuil-V3-–-mobile.png')} style={{ width: "60%", alignSelf: 'center' }} />
        </div>
      );
    } else if (user[0].user_id === 'A5733') {
      return (
        <div style={styles.section}>
          <p>Ainsi, dans la démarche actuelle visant à valoriser les choix plus respectueux des croissants et des chocolatines, vous pouvez constater que:</p>
          <ul>
            <li style={styles.li}>le croissant végétalien est l'option plus respectueuse pour le climat et est considérée comme ayant une empreinte faible</li>
            <li style={styles.li}>la chocolatine végétalienne est l'option plus respectueuse pour le climat et est considérée, tout de même, comme ayant une empreinte élevée.</li>
          </ul>
        </div>
      );
    }
  };

  return (
    <div style={commonStyles.container}>
      <div style={{ ...commonStyles.title, display: 'flex', alignItems: 'center', marginBottom: 60 }}>
        <FaInfoCircle size="1em" color={colors.main} />
        <div style={{ marginLeft: 10 }}>{t(state.title)}</div>
      </div>
      <div style={commonStyles.body}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 }}>
          {user[0].user_id === 'C38142' || user[0].user_id === 'A5733' ? <div style={styles.section}>
            <div style={styles.title}>La Démarche</div>
            <p>{formatText(text1)}</p>
          </div> : null}
          <div style={styles.section}>
            <div style={styles.title}>Empreinte carbone : Définition, objectif et seuils</div>
            <p>{formatText(text2)}</p>
          </div>
          <div style={styles.section}>
            <div style={styles.cellHeader}>Pour {user[0].name}</div>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <th style={{ ...styles.cell, ...styles.cellHeader, width: 200 }}>Catégorie</th>
                  <th style={{ ...styles.cell, ...styles.cellHeader, width: 200 }}>Seuil empreinte faible</th>
                  <th style={{ ...styles.cell, ...styles.cellHeader, width: 210 }}>Seuil empreinte élevée</th>
                  <th style={{ ...styles.cell, ...styles.cellHeader }}>Hypothèses</th>
                </tr>
                {renderRows(rows)}
              </tbody>
            </table>
          </div>
          {renderCategories()}
          <div style={styles.section}>
            <div style={styles.title}>L’estimation de l’empreinte carbone de vos choix alimentaires</div>
            <p>{formatText(text3)}</p>
            <div>Voici les étapes du cycle de vie comprise pour chaque source:</div>
            <div>Agribalyse: {renderLink("https://doc.agribalyse.fr/documentation/le-programme-agribalyse/introduction", ": de la production à la fin de vie")}</div>
            <div>Eaternity : {renderLink("https://eaternity.org/assets/edb/EDB-References-current.pdf", "de la production à la distribution")}</div>
            <div>Si aucune donnée n’existe pour un ingrédient :</div>
            <ul>
              <li style={styles.li}>Si son poids est d’environ 1% de la recette finale – nous faisons l’hypothèse que son empreinte est de 0 kgCO<sub style={styles.sub}>2</sub>e. Même si l’empreinte carbone de cet ingrédient serait élevée, sa contribution à l’empreinte de la recette finale sera négligeable.</li>
              <li style={styles.li}>Si son poids est supérieur à 1% de la recette finale, nous nous inspirons de recettes sur l’internet pour créer cet ingrédient afin d’obtenir une estimation de son empreinte.</li>
            </ul>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>Utilisation et transparence</div>
            <p>Afin de prévenir l’écoblanchiment, autant ERN que les services de la restauration sont tenus de se conformer aux principes suivants en ce qui concerne l’utilisation du logiciel Climat en Chef et des estimations d’empreinte carbone alimentaire qu’ils réalisent:</p>
            <ul>
              <li style={styles.li}>Afin d’offrir une transparence complète, les services de la restauration peuvent savoir, en tout temps, comment les ingrédients qui composent leurs recettes sont associés aux différentes sources de données d’empreinte carbone qu’utilise ERN.</li>
              <li style={styles.li}>Dans ses communications, afin de ne pas induire les consommateurs en erreur, le service de la restauration s’engage à ne pas utiliser de terme vague comme éco responsable, écologique, durable, bon pour l’environnement ou autre qui ne fournissent pas de clarté sur le bénéfice environnemental que le produit fourni.</li>
              <li style={styles.li}>Le service de la restauration s’engage à ne pas se comparer publiquement à d’autres entités en les nommant explicitement.</li>
            </ul>
            <p>Les données de base utilisées sont des empreintes moyennes. Ainsi, elles ne peuvent pas être utilisées pour comparer des ingrédients entre eux. Par exemple, elles ne peuvent pas être utilisées pour comparer l’empreinte entre deux cheddars.</p>
            <p>De plus, ces informations doivent être nuancées avec d’autres types d’impacts environnementaux et des considérations sanitaires. D’ailleurs, la santé est toujours la priorité. Il ne faut jamais essayer de réduire son empreinte carbone au détriment de sa santé. Nous recommandons toujours de consulter un.e professionnel.le de la santé compétent.e pour des considérations spécifiques.</p>
            <p>En ayant en tête ces nuances et limitations, les estimations d’empreinte carbone fournies par les logiciels d’ERN sont des excellentes sources d’information pour repérer les leviers d’actions au sein de menus et de recettes, pour avoir une idée de l’amélioration de l’empreinte de l’offre et des ventes et pour inciter sa clientèle à choisir les options à plus faible empreinte carbone.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    color: colors.text,
    fontSize: 16
  },
  title: {
    color: colors.main,
    fontSize: 24,
  },
  smallTitle: {
    color: colors.main,
  },
  disclaimer: {
    marginTop: 50,
    color: colors.text,
    fontSize: 14
  },
  link: {
    color: colors.link,
    textDecoration: 'none'
  },
  linkHover: {
    color: colors.grey,
    textDecoration: 'none'
  },
  sub: {
    lineHeight: 0
  },
  cell: {
    padding: 10,
    textAlign: 'left',
    lineHeight: 1.8,
    color: colors.text
  },
  cellHeader: {
    fontWeight: 500,
    color: colors.main
  },
  co2: {
    fontSize: 14,
    color: "#c8c8c8"
  },
  li: {
    marginBottom: 10
  }
};

export default InfosScreen;