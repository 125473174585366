import React from 'react';
import { colors } from '../util/values';

export const KgCO2e = ({ size, weight, color, portion, isAlone }) => {
  size = size ? size : 14;
  weight = weight ? weight : 400;
  color = color ? color : colors.text;

  return (
    <div style={{ fontFamilly: 'Inter', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: size, fontWeight: weight, color: color }}>
      <div>kgCO</div>
      <div style={{ fontSize: size - 4, alignSelf: 'flex-end' }}>2</div>
      <div>e</div>
      {isAlone ? null : <div>/{portion ? 'portion' : 'kg'}</div>}
    </div>
  );
};

export const NumberKgCO2e = ({ number, size, weight, color, portion }) => {
  size = size ? size : 14;
  weight = weight ? weight : 400;
  color = color ? color : colors.text;

  return (
    <div style={{ fontFamilly: 'Inter', display: 'flex', flexDirection: 'row', fontSize: size, fontWeight: weight, color: color }}>
      <div>{number} kgCO</div>
      <div style={{ fontSize: size - 6, alignSelf: 'flex-end' }}>2</div>
      <div>e/{portion ? 'portion' : 'kg'}</div>
    </div>
  );
};

export const SmallNumberKgCO2e = ({ prefix, number, size, sizeSmall, weight, color, portion }) => {
  size = size ? size : 14;
  sizeSmall = sizeSmall ? sizeSmall : 14;
  weight = weight ? weight : 400;
  color = color ? color : colors.text;

  return (
    <div style={{ fontFamilly: 'Inter', display: 'flex', flexDirection: 'row', fontSize: size, fontWeight: weight, color: color }}>
      <div style={{ marginRight: size / 4 }}>{prefix}</div>
      <div style={{ marginRight: size / 4 }}>{number}</div>
      <KgCO2e size={sizeSmall} portion />
    </div>
  );
};