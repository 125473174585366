import aws from '../api/aws';
import i18n from '../i18n';

export const login = async (email, password) => {
  const response = await aws.post('/login', { email, password });
  if (response.data && response.data !== "failed" && response.data.initial_setup === 2) {
    localStorage.setItem('user', JSON.stringify(response.data));
    let nav = {
      en: {
        recipes: response.data.name_recipes_en,
        subRecipes: response.data.name_subrecipes_en,
        ingredients: response.data.name_ingredients_en,
      },
      fr: {
        recipes: response.data.name_recipes_fr,
        subRecipes: response.data.name_subrecipes_fr,
        ingredients: response.data.name_ingredients_fr,
      }
    };
    localStorage.setItem('nav', JSON.stringify(nav));
    return response.data;
  } else {
    return null;
  }
};

export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('nav');
};

export const isAuthenticated = () => {
  const user = localStorage.getItem('user');
  if (!user) {
    return {}
  }
  getNavNames(JSON.parse(user));
  return JSON.parse(user);
};

const getNavNames = async (user) => {
  const response = await aws.get('/user', { params: { type: 'getNav', userId: user.user_id } });
  if (response.data) {
    let financialYear = { financial_month: response.data.financial_month, financial_day: response.data.financial_day };
    localStorage.setItem('financialYear', JSON.stringify(financialYear));
    let nav = {
      en: {
        recipes: response.data.name_recipes_en,
        subRecipes: response.data.name_subrecipes_en,
        ingredients: response.data.name_ingredients_en,
      },
      fr: {
        recipes: response.data.name_recipes_fr,
        subRecipes: response.data.name_subrecipes_fr,
        ingredients: response.data.name_ingredients_fr,
      }
    };
    localStorage.setItem('nav', JSON.stringify(nav));
    i18n.changeLanguage(localStorage.getItem('appLanguage'));
  }
};