import React, { useState, useEffect, createContext } from 'react';
//import { Redirect } from 'react-router-dom';
import { isAuthenticated } from './AuthService';

import LoginScreen from '../screens/LoginScreen';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const checkLoggedIn = async () => {
      let cuser = isAuthenticated();
      if (cuser === null) {
        localStorage.setItem('user', '');
        cuser = '';
      }

      setCurrentUser(cuser);
    };

    checkLoggedIn();
  }, []);

  return (
    <UserContext.Provider value={[currentUser, setCurrentUser]}>
      {currentUser?.user_id ? children : <LoginScreen />}
    </UserContext.Provider>
  );
};


export default UserContext;