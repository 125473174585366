import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useParams, useNavigate, useLocation, useNavigationType, NavigationType } from "react-router-dom";
import ReactLoading from 'react-loading';
import { AiOutlineMinusCircle } from "react-icons/ai";
import TextField from '@mui/material/TextField';
import { MdOutlineFilterAlt } from "react-icons/md";
import Checkbox from '@mui/material/Checkbox';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { NumberKgCO2e } from '../components/KgCO2e';
import { OrderArrows } from '../components/OrderArrows';
import { ButtonFull, ButtonEmpty } from '../components/Buttons';
//util
import { colors, colorValues } from '../util/values';
import { commonStyles } from '../util/styles';
import { getCount, sortBy, calculateCo2FromYield, getColor, formatNumber, co2ByYield, getIng } from '../util/climatecook';

const IngredientsScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [title, setTitle] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState("1");
  const [maxPageNumber, setMaxPageNumber] = useState("100");
  const [orderType, setOrderType] = useState("name");
  const [isOrderAsc, setIsOrderAsc] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [allSelectedCategories, setAllSelectedCategories] = useState(1);
  const [hasNullCategories, setHasNullCategories] = useState(true);
  const [isNullCategories, setIsNullCategories] = useState(true);
  const [hover, setHover] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCategories, setShowModalCategories] = useState(false);
  const [deleteId, setDeleteId] = useState(-1);
  const firstUpdate = useRef(true);

  const handleMouseEnter = (which) => {
    setHover(which);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  useEffect(() => {
    if ((location.state && location.state.isFromNav)) {
      localStorage.removeItem(params.which + "Values");
    }
    let nav = JSON.parse(localStorage.getItem("nav"));
    let newTitle = 'routeSharedIngredients';
    if (params.which === 'subing') {
      newTitle = nav != null ? nav[i18n.language].subRecipes : 'routeMySubRecipes';
    } else if (params.which === 'ing') {
      newTitle = nav != null ? nav[i18n.language].ingredients : 'routeMyIngredients';
    }
    setTitle(newTitle);

    let ingredientValues = JSON.parse(localStorage.getItem(params.which + "Values"));
    setIsNullCategories(ingredientValues ? ingredientValues.isNullCategories : true);
    setSearch(ingredientValues ? ingredientValues.search : "");
    setIsOrderAsc(ingredientValues ? ingredientValues.isOrderAsc : true);
    setOrderType(ingredientValues ? ingredientValues.orderType : "name");
    setPageNumber(ingredientValues ? ingredientValues.pageNumber : "1");
    setMaxPageNumber("100");
    getCategories();
  }, [params.which]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getIngredients(isOrderAsc, pageNumber);
  }, [categories]);

  useEffect(() => {
    allCheckboxState();
  }, [selectedCategories, isNullCategories]);

  const getCategories = async () => {
    const response = await aws.get('/categories', { params: { owner: params.which !== 'all' ? user[0].user_id : null, isRecipe: params.which === 'subing' ? 0 : 2 } });
    if (response.data) {
      response.data = response.data.filter((item) => item.id != null);
      let newSelectedCategories = {};
      let ingredientValues = JSON.parse(localStorage.getItem(params.which + "Values"));
      for (let cat of response.data) {
        newSelectedCategories[cat.id] = ingredientValues && ingredientValues.selectedCategories[cat.id] !== undefined ? ingredientValues.selectedCategories[cat.id] : true;
      }

      setSelectedCategories(newSelectedCategories);
      setCategories(response.data);
    }
  };

  const getIngredients = async (asc, pageStr) => {
    setShowModalCategories(false);
    let page = 1;
    if (!isNaN(pageStr)) {
      page = Number(pageStr);
    } else {
      return;
    }
    if (page < 1 || (page > Number(maxPageNumber) && maxPageNumber > 0)) {
      return;
    }
    let categoryIn = [];
    for (let key in selectedCategories) {
      if (selectedCategories[key]) {
        categoryIn.push(key);
      }
    }
    if (categoryIn.length === 0 && !isNullCategories) {
      setIngredients([]);
      return;
    }
    console.log(categoryIn);
    setIsReady(false);
    try {
      let response = null;
      if (params.which === 'all') {
        response = await aws.get('/ingredients/composition', { params: { user_id: user[0].user_id, isRecipe: 2, term: search.trim(), owner: null, page, categories: JSON.stringify(categoryIn), isNullCategories, asc, lang: i18n.language, region: 'CA' } });
      } else {
        response = await aws.get('/ingredients/composition', { params: { user_id: user[0].user_id, isRecipe: params.which === 'subing' ? 0 : 2, term: search.trim(), owner: user[0].user_id, page, categories: JSON.stringify(categoryIn), isNullCategories, asc, lang: i18n.language, region: 'CA' } });
      }
      if (response.data) {
        console.log(response.data);
        setPageNumber(page.toString());
        setMaxPageNumber(response.data.maxPageNumber.toString());

        let newFullIngredients = response.data.res;
        let hasNullCat = false;
        for (let i = 0; i < newFullIngredients.length; i++) {
          if (newFullIngredients[i].ing_level === 0) {
            let categoryName = null;
            newFullIngredients[i].thresholdHigh = colorValues.ingredientHigh;
            newFullIngredients[i].thresholdLow = colorValues.ingredientLow;
            for (let j = 0; j < categories.length; j++) {
              if (newFullIngredients[i].food_group == categories[j].id) {
                categoryName = i18n.language === "fr" ? categories[j].name_fr : categories[j].name_eng;
              }
            }
            newFullIngredients[i].categoryName = categoryName;

            if (newFullIngredients[i].food_group === null) {
              hasNullCat = true;
            }
          }
        }
        let newIngredients = [];
        for (let i = 0; i < newFullIngredients.length; i++) {
          if (newFullIngredients[i].ing_level === 0) {
            newIngredients.push(getIng(newFullIngredients, newFullIngredients[i]));
          }
        }
        console.log(newIngredients);
        setHasNullCategories(hasNullCat);

        if (i18n.language === "fr") {
          newIngredients = newIngredients.filter(item => item.name_fra !== "");
          newIngredients = newIngredients.sort(sortBy("name_fra", asc));
        } else {
          newIngredients = newIngredients.sort(sortBy("name_eng", asc));
        }
        setIngredients(newIngredients);

        if (Object.keys(selectedCategories).length !== 0) {
          let ingredientValues = {
            search: search,
            orderType: orderType,
            isOrderAsc: isOrderAsc,
            selectedCategories: selectedCategories,
            isNullCategories: isNullCategories,
            pageNumber: pageNumber
          };
          localStorage.setItem(params.which + "Values", JSON.stringify(ingredientValues));
        }
      }
      setIsReady(true);
    } catch (err) {
      console.log(err); //TODO handle error
    }
    setIsReady(true);
  };

  const onIngredientClick = (id) => {
    if (params.which !== 'all') {
      navigate("ingredient", { state: { ingredient: ingredients.find(i => i.ingredient_id === id), previousPage: params.which } });
    }
  };

  const onEnterPressed = (key) => {
    if (key === 'Enter') {
      getIngredients(isOrderAsc, pageNumber);
    }
  };

  const setOrder = (type) => {
    getIngredients(!isOrderAsc, pageNumber);
    setIsOrderAsc(!isOrderAsc);
  };

  const changePage = (direction) => {
    let page = 0;
    if (!isNaN(pageNumber)) {
      page = Number(pageNumber) + direction;
    } else {
      return;
    }
    getIngredients(isOrderAsc, page);
  };

  const onDelete = (event, id) => {
    event.stopPropagation();
    setDeleteId(id);
    setShowModal(true);
  };

  const onConfirmDelete = async () => {
    if (deleteId !== -1) {
      const response = await aws.delete('/ingredients/composition', { data: { id: deleteId, isArchived: 1 } });
      if (response.data) {
        getIngredients(isOrderAsc, pageNumber);
      }
    }
    setShowModal(false);
  };

  const allCheckboxState = () => {
    let trueCheckbox = null;
    let falseCheckbox = null;
    for (let key in selectedCategories) {
      if (selectedCategories[key] === true) {
        trueCheckbox = true;
      } else {
        falseCheckbox = true;
      }
    }
    if (isNullCategories === true) {
      trueCheckbox = true;
    } else {
      falseCheckbox = true;
    }

    if (trueCheckbox && !falseCheckbox) {
      setAllSelectedCategories(1);
    } else if (!trueCheckbox && falseCheckbox) {
      setAllSelectedCategories(0);
    } else {
      setAllSelectedCategories(2);
    }
  };

  const selectAllCheckbox = (checked) => {
    let newSelectedCategories = selectedCategories;
    setIsNullCategories(checked);
    for (let key in newSelectedCategories) {
      newSelectedCategories[key] = checked;
    }
    setSelectedCategories(newSelectedCategories);
    setAllSelectedCategories(checked ? 1 : 0);
  };

  const renderItemList = (itemList) => {
    return (
      <div>
        <table style={styles.table}>
          <tbody>
            <tr key="header">
              <th></th>
              <th></th>
              <th style={{ ...styles.headerCell, cursor: 'pointer' }} onClick={() => setOrder("name")}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <OrderArrows id="name" name={t('lblName')} stateType={orderType} stateIsAsc={isOrderAsc} />
                </div>
              </th>
              <th style={styles.headerCell}>{t('lblImpact')}</th>
              <th style={{ ...styles.headerCell }}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: allSelectedCategories !== 1 ? colors.main : colors.text, fontWeight: allSelectedCategories !== 1 ? 600 : 400 }} onClick={() => setShowModalCategories(!showModalCategories)}>
                  {t('lblCategory')}
                  <MdOutlineFilterAlt size={16} color={allSelectedCategories !== 1 ? colors.main : colors.text} />
                </div>
                {showModalCategories ?
                  <div style={styles.modalDivCategories}>
                    <div key={"parent"} style={styles.modalCheckbox}>
                      <Checkbox sx={{ p: 0, ml: -1, mr: 1, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main }, '&.MuiCheckbox-indeterminate': { color: colors.main } }}
                        size="small"
                        checked={allSelectedCategories === 1}
                        indeterminate={allSelectedCategories === 2}
                        onChange={(event) => selectAllCheckbox(event.target.checked)}
                      />
                      <div style={styles.modalCheckboxText}>{t('chkCategoriesAll')}</div>
                    </div>
                    {hasNullCategories ? <div key={"nullCategories"} style={styles.modalCheckbox}>
                      <Checkbox sx={{ p: 0, mr: 2, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main } }}
                        size="small"
                        checked={isNullCategories}
                        onChange={(event) => setIsNullCategories(event.target.checked)} />

                      <div style={styles.modalCheckboxText}>{t('lblNullCategories')}</div>
                    </div> : null}
                    {categories.map((item) => {
                      return (<div key={item.id + item.name_eng} style={styles.modalCheckbox}>
                        <Checkbox sx={{ p: 0, mr: 2, my: 0.5, color: colors.main, '&.Mui-checked': { color: colors.main } }}
                          size="small"
                          checked={selectedCategories[item.id]}
                          onChange={(event) => setSelectedCategories({ ...selectedCategories, [item.id]: event.target.checked })} />
                        <div style={styles.modalCheckboxText}>{i18n.language === "fr" ? item.name_fr : item.name_eng}</div>
                      </div>);
                    })}
                    <div style={{ marginBottom: 10 }} />
                    <ButtonFull text={t('btnOk')} onButtonClick={() => getIngredients(isOrderAsc, pageNumber)} />
                  </div> : null}
              </th>
              <th></th>
            </tr>
            {itemList.map((item) => {
              let itemCo2 = item.co2 / item.yield_kg;//co2ByYield(item.co2, item);
              let itemColor = getColor(itemCo2, 1, item.thresholdHigh, item.thresholdLow);

              return (
                <tr key={item.ingredient_id.toString()} style={{ backgroundColor: hover === item.ingredient_id ? colors.semiLightGrey : colors.lightGrey, cursor: params.which !== 'all' ? 'pointer' : 'default', transition: 'background-color 0.1s ease-out' }}
                  onMouseEnter={() => handleMouseEnter(item.ingredient_id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => onIngredientClick(item.ingredient_id)}>
                  <td style={{ ...styles.firstCell, backgroundColor: itemColor }}></td>
                  <td style={styles.gap}></td>
                  <td style={{ ...styles.cell }}>{i18n.language === "fr" ? item.name_fra : item.name_eng}</td>
                  <td style={{ ...styles.cell, width: 150 }}><div> </div><NumberKgCO2e number={formatNumber(itemCo2)} size={16} weight={800} color={itemColor} /></td>
                  <td style={{ ...styles.cell, width: 274, color: colors.text }}>{item.categoryName}</td>
                  <td style={styles.lastCell}>
                    {params.which !== 'all' ? <AiOutlineMinusCircle size={24} color={colors.delete} onClick={(event) => onDelete(event, item.ingredient_id)} /> : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {ingredients.length > 0 ?
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <ButtonFull text={t('btnPrevious')} onButtonClick={() => changePage(-1)} />
            <TextField style={{ margin: '0 10px', width: 40 }} id="page" variant="standard" value={pageNumber} onChange={(event) => setPageNumber(event.target.value)} onKeyPress={(event) => onEnterPressed(event.key)} />
            <div style={{ marginRight: 10 }}>of {maxPageNumber}</div>
            <ButtonFull text={t('btnNext')} onButtonClick={() => changePage(1)} />
          </div> : maxPageNumber === '0' ?
            <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '70%', fontSize: 25, fontWeight: 'bold', color: colors.main, textAlign: 'center' }}>{t("textEmptyIngredients")}</div>
            </div> : null
        }
      </div >
    );
  };

  return (
    <div style={commonStyles.container}>
      {showModal ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{t('lblDeleteIngredient')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnNo')} onButtonClick={() => setShowModal(false)} />
            <ButtonEmpty text={t('btnYes')} onButtonClick={() => onConfirmDelete()} />
          </div>
        </div>
      </div> : null}
      <div style={commonStyles.title}>{t(title)}</div>
      <div style={commonStyles.body}>
        <div style={styles.searchContainer}>
          <div style={styles.searchBar}>
            <InputBase
              sx={{ pl: 1, flex: 1 }}
              placeholder={t('btnSearch')}
              value={search}
              onChange={(event) => { setSearch(event.target.value); setPageNumber("1") }}
              onKeyPress={(event) => onEnterPressed(event.key)}
            />
            <IconButton type="button" sx={{ p: 0 }} onClick={() => getIngredients(isOrderAsc, pageNumber)}>
              <SearchIcon style={{ color: colors.main }} />
            </IconButton>
          </div>
          <div style={{ marginRight: 16 }} />
          {params.which !== 'all' ? <ButtonFull text={t('btnAdd')} onButtonClick={() => navigate("newIngredient", { state: {} })} /> : null}
        </div>
        {!isReady ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> : renderItemList(ingredients)
        }
      </div>
    </div>
  );
};

const styles = {
  searchContainer: {
    margin: '20px 20px 40px 20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'stretch',
    padding: '0 12px',
    flex: 1,
    border: '1px solid lightgrey',
    borderRadius: 12,
    backgroundColor: colors.lightGrey
  },

  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 10px'
  },
  headerCell: {
    padding: '0 20px',
    fontWeight: 400,
    textAlign: 'left',
    color: colors.text
  },
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  firstCell: {
    width: 6,
  },
  gap: {
    width: 6,
    backgroundColor: colors.background,
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },
  modalWindow: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 200,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 20,
    textAlign: 'center'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },

  modalDivCategories: {
    position: 'absolute',
    padding: '15px 20px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.grey,
    borderWidth: 1,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
  },
  modalCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  modalCheckboxText: {
    fontSize: 14,

  },
};

export default IngredientsScreen;