import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
//context
//api
import aws from '../api/aws';
//components
//util
import { colors } from '../util/values';

const AssociationBaseIngRow = ({ association, onChangeCallback }) => {
  const { t } = useTranslation();
  const [uniqueId, setUniqueId] = useState(null);
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState('');
  const [yieldKg, setYieldKg] = useState('');
  const [yieldL, setYieldL] = useState('');
  const [yieldUnit, setYieldUnit] = useState('');
  const [yieldPortion, setYieldPortion] = useState('');
  const [associationId, setAssociationId] = useState('');
  const [associationName, setAssociationName] = useState('');
  const [isConflict, setIsConflict] = useState(false);

  useEffect(() => {
    setUniqueId(association.uniqueId);
    setChecked(association.checked);
    setName(association.name);
    setAssociationId(association.associationId);
    setAssociationName(association.associationName);
  }, []);

  useEffect(() => {
    setYieldKg(association.yieldKg);
  }, [association.yieldKg]);

  useEffect(() => {
    setYieldL(association.yieldL);
  }, [association.yieldL]);

  useEffect(() => {
    setYieldUnit(association.yieldUnit);
  }, [association.yieldUnit]);

  useEffect(() => {
    setYieldPortion(association.yieldPortion);
  }, [association.yieldPortion]);

  useEffect(() => {
    setIsConflict(association.isConflict);
  }, [association.isConflict]);

  const onChange = (type, value) => {
    if (type === 'checked') {
      setChecked(value);
      if (value)
        setIsConflict(false);
    } else if (type === 'name')
      setName(value);
    else if (type === 'yieldKg')
      setYieldKg(value);
    else if (type === 'yieldL')
      setYieldL(value);
    else if (type === 'yieldUnit')
      setYieldUnit(value);
    else if (type === 'yieldPortion')
      setYieldPortion(value);
    else if (type === 'associationId')
      setAssociationId(value);
    else if (type === 'associationName')
      setAssociationName(value);
  };

  const onBlur = (type, value) => {
    onChangeCallback(type, uniqueId, value);
  };

  return (
    <tr style={{ ...styles.row, backgroundColor: (!checked && isConflict) ? '#ffd4d4' : null }}>
      <td style={styles.td}>
        <input style={styles.checkbox} type="checkbox" checked={checked} onChange={event => onChange('checked', event.target.checked)} onBlur={event => onBlur('checked', checked)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 250 }} value={name} onChange={event => onChange('name', event.target.value)} onBlur={event => onBlur('name', name)} />
      </td>
      <td style={styles.td}>
        <input style={styles.customTextField} value={yieldKg} onChange={event => onChange('yieldKg', event.target.value)} onBlur={event => onBlur('yieldKg', yieldKg)} />
      </td>
      <td style={styles.td}>
        <input style={styles.customTextField} value={yieldL} onChange={event => onChange('yieldL', event.target.value)} onBlur={event => onBlur('yieldL', yieldL)} />
      </td>
      <td style={styles.td}>
        <input style={styles.customTextField} value={yieldUnit} onChange={event => onChange('yieldUnit', event.target.value)} onBlur={event => onBlur('yieldUnit', yieldUnit)} />
      </td>
      <td style={styles.td}>
        <input style={styles.customTextField} value={yieldPortion} onChange={event => onChange('yieldPortion', event.target.value)} onBlur={event => onBlur('yieldPortion', yieldPortion)} />
      </td>
      <td style={styles.td}>
        <input style={styles.customTextField} value={associationId} onChange={event => onChange('associationId', event.target.value)} onBlur={event => onBlur('associationId', associationId)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 250 }} value={associationName} onChange={event => onChange('associationName', event.target.value)} onBlur={event => onBlur('associationName', associationName)} />
      </td>
    </tr>
  );
};

const styles = {
  row: {
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  td: {
    paddingRight: 20
  },
  checkbox: {
    width: 18,
    height: 18,
    accentColor: colors.main,
  },
  customTextField: {
    width: 150,
    padding: '4px 0 5px 0',
    fontFamily: 'Inter',
    fontSize: 16,
    border: 'none',
    transition: 'border-bottom 50ms cubic-bezier(0.4, 0, 0.2, 1)',
    outline: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.42)',
    backgroundColor: 'transparent'
  },
};

export default AssociationBaseIngRow;