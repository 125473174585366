import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { RiHeartAddFill } from "react-icons/ri";
//components
//context
import UserContext from '../context/UserContext';
//util
import { colors } from '../util/values';
import { commonStyles } from '../util/styles';

const HealthScreen = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <div style={commonStyles.container}>
      <div style={{ ...commonStyles.title, display: 'flex', alignItems: 'center', marginBottom: 60 }}>
        <RiHeartAddFill size="1em" color={colors.main} />
        <div style={{ marginLeft: 10 }}>{t(state.title)}</div>
      </div>
      <div style={commonStyles.body}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 }}>
          <div style={styles.title}>{t("text29")}</div>
          <div style={{ marginTop: 10, color: colors.text }}>{t("text27")}</div>
          <div style={styles.section}>
            <p style={styles.title}>{t("info1Title")}</p>
            <p>{t("info1Data1")}</p>
            <p>{t("info1Data2")}</p>
            <p>{t("info1Data3")}</p>
            <p>{t("info1Data4")}</p>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>{t("info2Title")}</div>
            <p>{t("info2Data1")}</p>
            <p>{t("info2Data2")}</p>
            <p>{t("info2Data3")}</p>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>{t("info3Title")}</div>
            <p style={styles.smallTitle}>{t("info3ListTitle1")}</p>
            <ul>
              <li>{t("info3ListData1")}</li>
              <li>{t("info3ListData2")}</li>
              <li>{t("info3ListData3")}</li>
            </ul>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>{t("info4Title")}</div>
            <p>{t("info4Data1")}</p>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>{t("info5Title")}</div>
            <p style={styles.smallTitle}>{t("info5ListTitle1")}</p>
            <ul>
              <li>{t("info5ListData1")}</li>
              <li>{t("info5ListData2")}</li>
              <li>{t("info5ListData3")}</li>
            </ul>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>{t("info6Title")}</div>
            <p>{t("info6Data1")}</p>
          </div>
          <div style={styles.section}>
            <div style={styles.title}>{t("info7Title")}</div>
            <p>{t("info7Data1")}</p>
            <p>{t("info7Data2")}</p>
          </div>
          <div style={styles.disclaimer}>{t("text30")}</div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    marginTop: 40,
    color: colors.text,
    fontSize: 16
  },
  title: {
    color: colors.main,
    fontSize: 24,
  },
  smallTitle: {
    color: colors.main,
  },
  disclaimer: {
    marginTop: 50,
    color: colors.text,
    fontSize: 14
  },
};

export default HealthScreen;