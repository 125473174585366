import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Routes, Route, NavLink, useOutlet, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
//context
import UserContext from './context/UserContext';
//components
import LoginScreen from './screens/LoginScreen';
import OptionsScreen from './screens/OptionsScreen';
import PreferencesScreen from './screens/PreferencesScreen';
import HealthScreen from './screens/HealthScreen';
import InfosScreen from './screens/InfosScreen';
import IngredientsScreen from './screens/IngredientsScreen';
import IngredientScreen from './screens/IngredientScreen';
import NewIngredientScreen from './screens/NewIngredientScreen';
import RecipesScreen from './screens/RecipesScreen';
import RecipeScreen from './screens/RecipeScreen';
import NewRecipeScreen from './screens/NewRecipeScreen';
import StatsOfferScreen from './screens/StatsOfferScreen';
import StatsSalesScreen from './screens/StatsSalesScreen';
import SalesDataScreen from './screens/SalesDataScreen';
import StatsPurchasesScreen from './screens/StatsPurchasesScreen';
import PurchasesDataScreen from './screens/PurchasesDataScreen';
import Associations from './screens/Associations';
//util
import ScrollToTop from "./components/ScrollToTop";
import { colors } from './util/values';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/" element={<LayoutWithNavBar />}>
        <Route path="" element={<Navigate to="/recipes" replace={true} />} />
        <Route path="/ingredients/:which" element={<IngredientsScreen />} />
        <Route path="/ingredients/:which/ingredient" element={<IngredientScreen />} />
        <Route path="/ingredients/:which/newIngredient" element={<NewIngredientScreen />} />
        <Route path="/recipes" element={<RecipesScreen />} />
        <Route path="/recipes/recipe" element={<RecipeScreen />} />
        <Route path="/recipes/newRecipe" element={<NewRecipeScreen />} />
        <Route path="/stats/offer" element={<StatsOfferScreen />} />
        <Route path="/stats/sales" element={<StatsSalesScreen />} />
        <Route path="/stats/salesData" element={<SalesDataScreen />} />
        <Route path="/stats/purchases" element={<StatsPurchasesScreen />} />
        <Route path="/stats/purchasesData" element={<PurchasesDataScreen />} />
        <Route path="/options" element={<OptionsScreen />} />
        <Route path="/options/preferences" element={<PreferencesScreen />} />
        <Route path="/options/health" element={<HealthScreen />} />
        <Route path="/options/infos" element={<InfosScreen />} />
        <Route path="/associations" element={<Associations />} />
      </Route>
    </Routes>
  );
};

const Navbar = () => {
  const { i18n, t } = useTranslation();
  const user = useContext(UserContext);
  const location = useLocation();
  const [nameRecipes, setNameRecipes] = useState(t('routeMyRecipes'));
  const [nameSubRecipes, setNameSubRecipes] = useState(t('routeMySubRecipes'));
  const [nameIngredients, setNameIngredients] = useState(t('routeMyIngredients'));
  const handleLanguageChanged = useCallback(() => {
    getNames();
  }, []);

  //for languageChanged to only be subscribed once
  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  const getNames = async () => {
    let navNames = localStorage.getItem('nav');
    let tempNameRecipes = t('routeMyRecipes');
    let tempNameSubRecipes = t('routeMySubRecipes');
    let tempNameIngredients = t('routeMyIngredients');
    if (navNames != null) {
      navNames = JSON.parse(navNames);
      const language = i18n.language;
      tempNameRecipes = navNames[language].recipes !== null ? navNames[language].recipes : t('routeMyRecipes');
      tempNameSubRecipes = navNames[language].subRecipes !== null ? navNames[language].subRecipes : t('routeMySubRecipes');
      tempNameIngredients = navNames[language].ingredients !== null ? navNames[language].ingredients : t('routeMyIngredients');
    } else {
      let newNavNames = {
        en: {
          recipes: t("routeMyRecipes", { lng: 'en' }),
          subRecipes: t("routeMySubRecipes", { lng: 'en' }),
          ingredients: t("routeMyIngredients", { lng: 'en' })
        },
        fr: {
          recipes: t("routeMyRecipes", { lng: 'fr' }),
          subRecipes: t("routeMySubRecipes", { lng: 'fr' }),
          ingredients: t("routeMyIngredients", { lng: 'fr' })
        }
      };
      localStorage.setItem("nav", JSON.stringify(newNavNames));
    }
    setNameRecipes(tempNameRecipes);
    setNameSubRecipes(tempNameSubRecipes);
    setNameIngredients(tempNameIngredients);
  };

  return (
    <div id="nav" style={styles.container1}>
      <div style={styles.container2}>
        <img style={styles.logo} src={user[0].image_url ? user[0].image_url : require('./assets/icon1024.png')} />
        <div style={styles.name}>{user[0].name}</div>
        <div style={(new RegExp('^\/recipes.*')).test(location.pathname) || (new RegExp('^\/ingredients.*')).test(location.pathname) ? { ...styles.category, ...styles.active } : styles.category}>{t('routeCooking')}</div>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/recipes" state={{ isFromNav: true }}>{nameRecipes}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/ingredients/subing" state={{ isFromNav: true }}>{nameSubRecipes}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/ingredients/ing" state={{ isFromNav: true }}>{nameIngredients}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/ingredients/all" state={{ isFromNav: true }}>{t('routeSharedIngredients')}</NavLink>
        <div style={(new RegExp('^\/stats.*')).test(location.pathname) ? { ...styles.category, ...styles.active } : styles.category}>{t('routeStats')}</div>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/stats/offer">{t('routeStatsOffer')}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/stats/sales">{t('routeStatsSales')}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/stats/salesData">{t('routeSalesData')}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/stats/purchases">{t('routeStatsPurchases')}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.link, ...styles.active } : styles.link} to="/stats/purchasesData">{t('routePurchasesData')}</NavLink>
        <NavLink style={({ isActive }) => isActive ? { ...styles.category, ...styles.active } : styles.category} to="/options">{t('routeAccount')}</NavLink>
      </div>
      <div style={styles.smallContainer1}>
        <div style={styles.smallContainer2}>
          <div><img style={styles.smallLogo} src={require('./assets/iconTransparent1110.png')} /></div>
          <div style={styles.smallContainer3}>
            <div style={styles.smallTitle}>{t('smallTitle')}</div>
            <div style={styles.smallText}>{t('smallText1')}</div>
            <div style={styles.smallText}>{t('smallText2')}</div>
          </div>
        </div>
        <div style={styles.smallCopyright}>{t('smallText3')}</div>
      </div>
    </div>
  );
};

const LayoutWithNavBar = () => {
  const outlet = useOutlet();

  return (
    <div id="App" style={{ height: '100vh', display: 'flex', flexDirection: 'row', position: 'relative', overflowY: 'scroll' }}>
      <ScrollToTop />
      <Navbar />
      <div style={{ flexGrow: 1 }}>
        {outlet}
      </div>
    </div>
  );
};

const styles = {
  container1: {
    width: 300,
    height: '100vh',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.lightGrey,
    position: 'sticky',
    top: 0
  },
  container2: {
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 180,
    marginBottom: 20,
    borderRadius: 14
  },
  name: {
    marginBottom: 20,
    fontSize: 24,
    fontWeight: 700,
    color: colors.black,
    textAlign: 'center'
  },
  category: {
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'flex-start',
    fontSize: 20,
    fontWeight: 500,
    color: colors.main,
    textDecoration: 'none'
  },
  link: {
    marginBottom: 10,
    marginLeft: 30,
    alignSelf: 'flex-start',
    fontSize: 14,
    fontWeight: 400,
    color: colors.text,
    textDecoration: 'none'
  },
  active: {
    fontWeight: 700,
    color: colors.main
  },
  unclickable: {
    pointerEvents: 'none'
  },

  smallContainer1: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    color: colors.main
  },
  smallContainer2: {
    display: 'flex',
    flexDirection: 'row'
  },
  smallLogo: {
    marginRight: 10,
    marginBottom: 10,
    width: 80
  },
  smallContainer3: {
    display: 'flex',
    flexDirection: 'column'
  },
  smallTitle: {
    fontSize: 20
  },
  smallText: {
    marginBottom: 4
  },
};

export default AppRoutes;