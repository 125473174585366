import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { VictoryChart, VictoryStack, VictoryBar, VictoryAxis } from 'victory';
//components
import { SmallNumberKgCO2e, KgCO2e } from '../components/KgCO2e';
//util
import { colors, colorValuesRecipes } from '../util/values';
import { calculateGraph } from '../util/climatecook';

export const Graph = ({ data, categories, menus }) => {
  const { t } = useTranslation();
  const [allData, setAllData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allMenus, setAllMenus] = useState([]);

  const [average, setAverage] = useState(0);
  const [dataGreen, setDataGreen] = useState([{ x: 1, co2: 0 }]);
  const [dataYellow, setDataYellow] = useState([{ x: 1, co2: 0 }]);
  const [dataRed, setDataRed] = useState([{ x: 1, co2: 0 }]);
  const [tickValues, setTickValues] = useState([0.5, 1, 1.5]);
  const [categoryPickerList, setCategoryPickerList] = useState([""]);
  const [categoryPicker, setCategoryPicker] = useState(categoryPickerList[0]);
  const [menuPickerList, setMenuPickerList] = useState([""]);
  const [menuPicker, setMenuPicker] = useState(menuPickerList[0]);

  useEffect(() => {
    setAllData(data);
  }, [data]);

  useEffect(() => {
    setAllCategories(categories);
    setCategoryPickerList(categories);
    setCategoryPicker(categories[0]);
  }, [categories]);

  useEffect(() => {
    setAllMenus(menus);
    setMenuPickerList(menus);
    setMenuPicker(menus[0]);
  }, [menus]);


  useEffect(() => {
    updateGraph(categoryPicker);
  }, [allData, categoryPicker, menuPicker]);

  const updateGraph = async (category) => {
    let result = calculateGraph(allData, menuPicker, category, true);

    setTickValues(result.tickValues);
    setAverage(result.impact);
    setDataGreen(result.dataGreen);
    setDataYellow(result.dataYellow);
    setDataRed(result.dataRed);
  };

  const cornerRadius = (item) => {
    if (item.isHere) {
      return 8;
    }
    return 0;
  };

  const label = (item) => {
    if (item.isHere && item.x === 1) {
      return item.co2.toFixed(3);
    }
    return null;
  };

  return (
    <div style={styles.graph}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
        <div style={{ marginRight: 20, color: colors.text }}>{t('lblMenu')}</div>
        <Select sx={styles.unit} variant="standard" value={menuPicker} onChange={(event) => setMenuPicker(event.target.value)}>
          {menuPickerList.map(item => <MenuItem key={item} value={item}>{item !== "null" ? item : t('lblNullMenus')}</MenuItem>)}
        </Select>
      </div>
      <div style={styles.header}>
        <Select sx={styles.unit} variant="standard" value={categoryPicker} onChange={(event) => setCategoryPicker(event.target.value)}>
          {categoryPickerList.map(item => <MenuItem key={item} value={item}>{item !== "offer" ? item : t('lblOffer')}</MenuItem>)}
        </Select>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontSize: 18, fontWeight: 700, color: colors.main }}>{t("lblAvgImpact")} {average.toFixed(3)}</div>
          <KgCO2e size={12} weight={400} color={colors.text} portion={1} />
        </div>
      </div>
      <div style={{ margin: '30px 0 -20px 0', fontSize: 12 }}><KgCO2e size={11} portion /></div>
      <div style={{ width: 400 }}>
        <VictoryChart height={400} domain={{ x: [0, 3] }} domainPadding={{ x: [0, 0], y: 0 }}>
          <VictoryStack colorScale={[colors.good, colors.warning, colors.bad,]} >
            <VictoryBar style={styles.victoryBar} data={dataGreen} x="x" y="co2" labels={({ datum }) => label(datum)} cornerRadius={{ topLeft: ({ datum }) => cornerRadius(datum), topRight: ({ datum }) => cornerRadius(datum) }} />
            <VictoryBar style={styles.victoryBar} data={dataYellow} x="x" y="co2" labels={({ datum }) => label(datum)} cornerRadius={{ topLeft: ({ datum }) => cornerRadius(datum), topRight: ({ datum }) => cornerRadius(datum) }} />
            <VictoryBar style={styles.victoryBar} data={dataRed} x="x" y="co2" labels={({ datum }) => label(datum)} cornerRadius={{ topLeft: ({ datum }) => cornerRadius(datum), topRight: ({ datum }) => cornerRadius(datum) }} />
          </VictoryStack>
          <VictoryAxis
            style={styles.victoryAxisX}
            tickValues={[1, 2]}
            tickFormat={[categoryPicker !== "offer" ? categoryPicker : t('lblOffer'), t("lblReference")]}
          />
          <VictoryAxis
            style={styles.victoryAxisY}
            dependentAxis
            tickValues={tickValues}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

const styles = {
  graph: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
  },
  unit: {
    marginRight: 2,
    fontFamily: 'Inter',
    width: 120,
    fontSize: 15,
    fontWeight: 400,
    color: colors.textNegative,
    textAlign: 'center',
    backgroundColor: colors.main,
    borderRadius: 2.5,
    transition: 'background-color 0.1s linear',
    '&:hover': { backgroundColor: colors.darkGray },
    '&:before': { border: 'none' },
    '&:after': { border: 'none' },
    '&:not(.Mui-disabled):hover::before': { border: 'none' },
    '.MuiSelect-icon': { display: 'none' },
    '.MuiSelect-select': { padding: '5px 20px !important', width: 120 }
  },

  victoryAxisX: {
    axis: { stroke: colors.text, strokeWidth: 0.5 },
    tickLabels: { fontSize: 16, padding: 5, fill: colors.text }
  },
  victoryAxisY: {
    axis: { stroke: "transparent" },
    /*grid: {
      stroke: colors.lightGrey,
      strokeDasharray: "10, 15",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    },*/
    ticks: { stroke: colors.lightGrey, size: 5 },
    tickLabels: { fontSize: 16, padding: 5, fill: colors.text }
  },
  victoryBar: {
    data: { width: 60 },
    labels: {
      fontSize: 16,
      fill: colors.text
    }
  }
};