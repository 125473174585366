import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, fr } from "./translations/translation";

const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
};

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    fallbackLng: "fr",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;