import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import i18n from '../i18n';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { ButtonFull } from '../components/Buttons';
//util
import { colors } from '../util/values';
import { commonStyles } from '../util/styles';
import { getCount } from '../util/climatecook';

const NewIngredientScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");

  useEffect(() => {
    let nav = JSON.parse(localStorage.getItem("nav"));
    let newTitle = 'routeSharedIngredients';
    if (params.which === 'subing') {
      newTitle = nav != null ? nav[i18n.language].subRecipes : 'routeMySubRecipes';
    } else if (params.which === 'ing') {
      newTitle = nav != null ? nav[i18n.language].ingredients : 'routeMyIngredients';
    }
    setTitle(t(newTitle));
  }, [params.which]);

  const newIngredientManually = () => {
    let ingredient = {
      ing_level: 0,
      main_ingredient_id: null,
      ingredient_id: null,
      unit: "gr",
      quantity: 0,
      name_fra: "",
      name_eng: "",
      owner_name_fra: "",
      owner_name_eng: "",
      main_unit: "kg",
      owner: user[0].user_id,
      density: 1,
      unit_single: 1,
      unit_mass: 1,
      unit_volume: 1,
      unit_liquid: 1,
      unitWeight: 1,
      yield_kg: null,
      yield_l: null,
      yield_unit: null,
      food_group: null,
      menu1: null,
      menu2: null,
      menu3: null,
      ern_ingredient_code: null,
      partner_ingredient_code: null,
      co2: 0,
      region: null,
      alternative: null,
      altErnIngCode: null,
      altPatnerIngCode: null,
      altCo2: null,
      altRegion: null,
      portions: 0,
      image_url: null,
      cook_time: null,
      is_recipe: 0,
      recipeCo2: null,
      uniqueId: getCount()
    };

    navigate("/ingredients/user/ingredient", { state: { ingredient, isNew: true, which: params.which } });
  };

  return (
    <div style={commonStyles.container}>
      <div style={commonStyles.title}>{title}</div>
      <div style={commonStyles.body}>
        {/*<div style={styles.option}>
          <div style={styles.title}>{t('lblLang')}</div>
          <div style={styles.buttons}>
            <div>English</div>
            <div>Français</div>
          </div>
        </div>*/}
        <div style={styles.option}>
          <div style={styles.title}>{t(params.which === 'subing' ? 'lblAddSubRecipe' : 'lblAddIngredient')}</div>
          <div style={styles.buttons}>
            {/*<ButtonFull text={t('btnImportFile')} onButtonClick={() => { }} />
            <ButtonFull text={t('btnImportAPI')} onButtonClick={() => { }} />
      <ButtonFull text={t('btnDownloadLink')} onButtonClick={() => { }} />*/}
            <ButtonFull text={t('btnManually')} onButtonClick={() => newIngredientManually()} />
          </div>
        </div>
        {/*<div style={styles.option}>
          <div style={styles.title}>{t('lblImportAll')}</div>
          <div style={styles.buttons}>
            <ButtonFull text={t('btnImportFile')} onButtonClick={() => { }} />
            <ButtonFull text={t('btnImportAPI')} onButtonClick={() => { }} />
          </div>
        </div>*/}
      </div>
    </div>
  );
};

const styles = {
  option: {
    margin: '20px 20px 40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  title: {
    marginBottom: 20,
    color: colors.text,
    fontSize: 30,
    fontWeight: 400,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20
  }
};

export default NewIngredientScreen;