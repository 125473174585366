import React, { useEffect, useState } from 'react';
//util
import { colors } from '../util/values';

export const ButtonOption = ({ text, onButtonClick, width, color }) => {
  const [hover, setHover] = useState(false);
  const handleClick = () => {
    onButtonClick();
  };

  return (
    <div
      style={hover ? { ...styles.option, ...styles.optionSelected, width: width ? width : 70, color: color ? color : colors.main } : { ...styles.option, width: width ? width : 70, color: color ? color : colors.darkGray }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >{text}</div>
  );
};

export const RadioOption = ({ text, onButtonClick, isSelected }) => {
  const [hover, setHover] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isSelected != null ? isSelected : false);
  }, [isSelected]);

  const handleClick = () => {
    setSelected(!selected);
    onButtonClick(!selected);
  };

  return (
    <div
      style={selected || hover ? { ...styles.option, ...styles.optionSelected } : styles.option}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >{text}</div>
  );
};

const styles = {
  option: {
    width: 70,
    fontWeight: 400,
    color: colors.darkGray,
    cursor: 'pointer',
    transition: 'color 0.2s ease-out',

  },
  optionHover: {
    color: colors.main,
  },
  optionSelected: {
    color: colors.main,
    fontWeight: 600,
  }
};