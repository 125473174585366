import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FaLink } from "react-icons/fa";
//util
import { colors, completeUnits } from '../util/values';

export const Yield = ({ yieldKey, label, availableUnits, unit, onChangeUnit, quantity, isAuto, error, optionnal, onChangeQuantity, onChangeIsAuto }) => {
  const { t } = useTranslation();
  const [quantityStr, setQuantityStr] = useState("");
  const [isAuto2, setIsAuto2] = useState(false);

  useEffect(() => {
    if (quantity === 0) {
      setQuantityStr("");
    } else if (quantity !== null) {
      setQuantityStr(quantity + "");
    }
  }, [quantity]);

  useEffect(() => {
    setIsAuto2(isAuto);
  }, [isAuto]);

  const onChangeQuantity2 = (value) => {
    setQuantityStr(value)
    let newValue = 0;
    if (!isNaN(value)) {
      newValue = Number(value);
    }
    onChangeQuantity(yieldKey, newValue);
  };

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20, cursor: 'pointer' }}>
        <FaLink onClick={() => onChangeIsAuto(yieldKey, !isAuto2)} color={isAuto2 ? colors.good : colors.grey} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={styles.label}>{label}</div>
        {optionnal ? <div style={{ fontSize: 10, color: colors.text }}>{t('lblOptionnal')}</div> : null}
      </div>

      <TextField sx={{ input: styles.yieldInput, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
        id="yieldQuantity"
        variant="standard"
        value={quantityStr}
        error={error !== ""}
        helperText={error}
        onChange={(event) => onChangeQuantity2(event.target.value)}
        disabled={isAuto2} />
      <Select
        sx={{ ...styles.yieldUnit, ml: 3, borderRadius: 1.5, '&:before': { border: 'none' }, '&:after': { border: 'none' }, '&:not(.Mui-disabled):hover::before': { border: 'none' }, '.MuiSelect-icon': { display: 'none' }, '.MuiSelect-select': { padding: '0 !important', width: 50 } }}
        variant="standard"
        value={unit}
        onChange={(event) => onChangeUnit(yieldKey, event.target.value)}
      >
        {availableUnits.map(item => <MenuItem key={item.code} value={item}>{t(item.code)}</MenuItem>)}
      </Select>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  label: {
    marginRight: 20,
    width: 50,
    color: colors.text,
  },
  yieldInput: {
    fontFamily: 'Inter',
    pb: 0,
    width: 50,
    color: colors.text
  },
  yieldUnit: {
    fontFamily: 'Inter',
    marginLeft: 24,
    width: 50,
    fontSize: 15,
    fontWeight: 600,
    color: colors.darkGray,
    textAlign: 'center',
    backgroundColor: colors.grey,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: colors.darkGray,
    borderWidth: 1,
  },
};

export default Yield;