import React, { useState, useEffect, useRef, memo } from 'react';
import { useTranslation } from "react-i18next";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isEqual from 'lodash.isequal';
//context
//api
import aws from '../api/aws';
//components
import { ButtonFull } from './Buttons';
//util
import { colors, completeUnitsWithPortion, massUnits, volumesUnits } from '../util/values';
import { toLowerNoAccents, fromAnyToKg2, fromAnyToL2, fromAnyToUnit2, is1For1Ing } from '../util/climatecook';

const AssociationRow = ({ association, autoComplete, onChangeCallback }) => {
  const { t } = useTranslation();
  const [uniqueId, setUniqueId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [quantityStr, setQuantityStr] = useState('');
  const [unit, setUnit] = useState(completeUnitsWithPortion[3]);
  const [ingredient, setIngredient] = useState('');
  const [isAssoChecked, setIsAssoChecked] = useState(false);
  const [associationNameObject, setAssociationNameObject] = useState({});
  const [associationNameInput, setAssociationNameInput] = useState('');
  const [isUnitCompatible, setIsUnitCompatible] = useState(false);
  const [which, setWhich] = useState(null);
  const [clarification, setClarification] = useState('');
  const [alternative, setAlternative] = useState('');
  const [step, setStep] = useState('');
  const [rawIngredient, setRawIngredient] = useState('');
  const [yieldAssoKg, setYieldAssoKg] = useState(null);
  const [yieldAssoL, setYieldAssoL] = useState(null);
  const [yieldAssoUnit, setYieldAssoUnit] = useState(null);
  const [yieldAssoPortion, setYieldAssoPortion] = useState(null);
  const [isAutoCompleteFocused, setIsAutoCompleteFocused] = useState(false);
  const [hover, setHover] = useState(null);
  const [hoverAssociation, setHoverAssociation] = useState(null);
  const [buttonColor, setButtonColor] = useState(colors.main);

  useEffect(() => {
    setUniqueId(association.uniqueId);
    setIsChecked(association.checked ? true : false);
    setQuantity(association.qty);
    setQuantityStr(association.qty != null ? association.qty + '' : '');
    let u = getUnit(association.unit);
    setUnit(u ? u : completeUnitsWithPortion[3]);
    setIngredient(association.name ? association.name : '');
    setClarification(association.clar ? association.clar : '');
    setAlternative(association.alt ? association.alt : '');
    setStep(association.step ? association.step : '');
    setRawIngredient(association.all ? association.all : '');
  }, []);

  useEffect(() => {
    setIsAssoChecked(association.assoChecked ? true : false);
  }, [association.assoChecked]);

  useEffect(() => {
    setAssociationNameObject(association.associationId ? { id: association.associationId, name: association.associationName, owner: association.associationOwner } : {});
    setAssociationNameInput(association.association ? association.association : '');
  }, [association.associationId, association.associationName, association.associationOwner]);

  useEffect(() => {
    setYieldAssoKg(association.yieldAssoKg ? association.yieldAssoKg : null);
  }, [association.yieldAssoKg]);

  useEffect(() => {
    setYieldAssoL(association.yieldAssoL ? association.yieldAssoL : null);
  }, [association.yieldAssoL]);

  useEffect(() => {
    setYieldAssoUnit(association.yieldAssoUnit ? association.yieldAssoUnit : null);
  }, [association.yieldAssoUnit]);

  useEffect(() => {
    setYieldAssoPortion(association.yieldAssoPortion ? association.yieldAssoPortion : 0);
  }, [association.yieldAssoPortion]);

  useEffect(() => {
    setIsUnitCompatible(association.unitCompatible ? true : false);
  }, [association.unitCompatible]);

  useEffect(() => {
    setWhich(association.which ? association.which : null);
  }, [association.which]);

  /*const handleMouseEnter = () => {
    setHoverAssociation(true);
  };

  const handleMouseLeave = () => {
    setHoverAssociation(false);
  };*/

  const onChangeName = async (newValue) => {
    if (newValue === null) {
      return;
    }
    console.log(newValue);
    setIsAutoCompleteFocused(false);
    let newValue1 = newValue;
    let newValue2 = newValue.nom;
    let newValue3 = newValue;
    let mainIng = {
      owner: newValue.owner,
      unit: newValue.unit,
      quantity: newValue.quantity,
      yield_kg: newValue.yieldKg,
      yield_l: newValue.yieldL,
      yield_unit: newValue.yieldUnit,
      portions: newValue.yieldPortion,
      composition: newValue.composition.map(v => {
        return {
          id: v.id,
          unit: v.unit,
          quantity: v.quantity,
          yield_kg: v.yieldKg,
          yield_l: v.yieldL,
          yield_unit: v.yieldUnit,
          portions: v.yieldPortion,
          owner: v.owner,
          composition: []
        };
      })
    };
    let [is1For1, other1For1] = is1For1Ing(mainIng);
    console.log(is1For1, other1For1);
    if (is1For1 && (newValue.owner !== 'ERN' && newValue.owner !== null)) {
      newValue1 = newValue.composition[0];
      newValue2 = newValue.composition[0].nom;
      newValue3 = newValue.composition[0];
    }
    setAssociationNameObject(newValue1);
    setAssociationNameInput(newValue2);
    let u = getUnit(unit.name);
    let newIsUnitCompatible = u && ((massUnits[u.name] && newValue3.yieldKg !== null) || (volumesUnits[u.name] && newValue3.yieldL !== null) || (u.name === 'unit' && newValue3.yieldUnit !== null) || (u.name === 'portions' && newValue3.yieldPortion !== 0));
    setIsUnitCompatible(newIsUnitCompatible);
    let newWhich = 7;
    setWhich(newWhich);
    setYieldAssoKg(newValue1.yieldKg);
    setYieldAssoL(newValue1.yieldL);
    setYieldAssoUnit(newValue1.yieldUnit);
    setYieldAssoPortion(newValue1.yieldPortion);
    onChangeCallback("associationObject", uniqueId, { ...newValue1, unitCompatible: newIsUnitCompatible, which: newWhich });
  };

  const duplicateIngredient = async () => {
    if (associationNameObject.id != null) {
      setButtonColor(colors.amber);
      const response = await aws.put('/association', { id: associationNameObject.id });
      setButtonColor(colors.main);
    }
  };

  const getUnit = (unit) => {
    if (unit == null)
      return false;
    for (let c of completeUnitsWithPortion) {
      if (c.others.includes(toLowerNoAccents(unit).replaceAll(' ', ''))) {
        return c;
      }
    }
    return false;
  };

  const is1For1 = (ing) => {
    if (ing.composition.length === 1) {
      let unit = getUnit(ing.composition[0].unit);
      let is1For1 = false;
      if (ing.yieldKg !== null) {
        is1For1 = ing.yieldKg === fromAnyToKg2(unit.name, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit);
      } else if (ing.yieldL !== null) {
        is1For1 = ing.yieldL === fromAnyToL2(unit.name, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit);
      } else if (ing.yieldUnit !== null) {
        is1For1 = ing.yieldUnit === fromAnyToUnit2(unit.name, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit);
      } else if (ing.yieldPortion !== 0) {
        is1For1 = ing.yieldPortion === ing.composition[0].quantity * ing.composition[0].yieldPortion;
      }
      return is1For1;
      //if (unit && ing.yieldKg === fromAnyToKg2(unit.name, ing.composition[0].quantity, ing.composition[0].yieldKg, ing.composition[0].yieldL, ing.composition[0].yieldUnit)) {
      //  return true;
      //}
    }
    return false;
  };

  const renderAutocomplete = () => {
    let list = [];
    let found = autoComplete.find(a => a.id === associationNameObject.id);
    if (associationNameObject.id != null && found) {
      list.push(<div key={found.id}
        style={{ padding: 4, cursor: 'pointer', backgroundColor: hover === found.id ? colors.lightGrey : null }}
        onMouseEnter={() => setHover(found.id)}
        onMouseLeave={() => setHover(null)}
        onClick={() => onChangeName(found)}>{found.nom}</div>);
    }
    for (let a of autoComplete) {
      if (toLowerNoAccents(a.nom).includes(toLowerNoAccents(associationNameInput)) && a.id != associationNameObject.id) {
        list.push(<div key={a.id}
          style={{ padding: 4, cursor: 'pointer', backgroundColor: hover === a.id ? colors.lightGrey : null }}
          onMouseEnter={() => setHover(a.id)}
          onMouseLeave={() => setHover(null)}
          onClick={() => onChangeName(a)}>{a.nom}</div>);
      }
    }
    return list;
  };

  const getRowColor = () => {
    if (which == null) {
      return 'none';
    } else if (which === -1) {
      return '#ffd4d4';
    } else if ([1, 2, 3, 7].includes(which)) {
      return '#e1ffd4';
    } else if (which === 4) {
      return '#d4edff';
    } else if (which === 5) {
      return '#ffeed4';
    } else if (which === 6) {
      return '#ffd4d4';
    } else {
      return 'none'
    }
  };

  const getCheckColor = () => {
    return 'transparent';
    if (ingredient != null && ingredient !== '' && associationNameObject.id != null & (associationNameObject.owner == null || associationNameObject.owner === 'ERN') && isUnitCompatible) {
      return 'transparent';
    } else {
      return colors.red;
    }
  };

  const onChangeQuantity = (value) => {
    setQuantityStr(value);
    if (!isNaN(value)) {
      setQuantity(+value);
      //onChangeCallback('qty', uniqueId, +value);
    }
  };

  const onChangeAssoChecked = (value) => {
    if ((associationNameObject.owner == null || associationNameObject.owner === 'ERN') && isUnitCompatible) {
      setIsAssoChecked(value);
      //onChangeCallback('assoChecked', uniqueId, value);
    } else {
      setIsAssoChecked(false);
    }
  };

  const onChange = (type, value) => {
    if (type === 'checked')
      setIsChecked(value);
    else if (type === 'unit') {
      setUnit(value);
      let u = getUnit(value.name);
      let newIsUnitCompatible = u && ((massUnits[u.name] && yieldAssoKg !== null) || (volumesUnits[u.name] && yieldAssoL !== null) || (u.name === 'unit' && yieldAssoUnit !== null) || (u.name === 'portions' && yieldAssoPortion !== 0));
      setIsUnitCompatible(newIsUnitCompatible);
      let v = { unit: value, compat: newIsUnitCompatible };
      onChangeCallback(type, uniqueId, v);
      return;
    } else if (type === 'name')
      setIngredient(value);
    else if (type === 'associationInput') {
      setAssociationNameInput(value);
      setAssociationNameObject({});
      setWhich(null);
      setYieldAssoKg(null);
      setYieldAssoL(null);
      setYieldAssoUnit(null);
      setYieldAssoPortion(0);
      setIsUnitCompatible(false);
    } else if (type === 'clar')
      setClarification(value);
    else if (type === 'alt')
      setAlternative(value);
    else if (type === 'step')
      setStep(value);
    else if (type === 'all')
      setRawIngredient(value);
    //onChangeCallback(type, uniqueId, value);
  };

  const onBlur = (type, value) => {
    if (type === 'assoChecked') {
      if ((associationNameObject.owner == null || associationNameObject.owner === 'ERN') && isUnitCompatible)
        onChangeCallback(type, uniqueId, value);
      else
        onChangeCallback(type, uniqueId, false);
    } else if (type === 'qty') {
      if (!isNaN(value))
        onChangeCallback(type, uniqueId, value);
    } else
      onChangeCallback(type, uniqueId, value);
  };

  return (
    <tr style={{ ...styles.row, backgroundColor: getRowColor() }} id={"ing" + uniqueId} className="ing">
      <td style={{ ...styles.td, backgroundColor: getCheckColor() }}>
        <input style={styles.checkbox} type="checkbox" checked={isChecked} onChange={event => onChange('checked', event.target.checked)} onBlur={event => onBlur('checked', isChecked)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 50 }} value={quantityStr} onChange={event => onChangeQuantity(event.target.value)} onBlur={event => onBlur('qty', quantity)} />
      </td>
      <td style={styles.td}>
        <Select
          sx={{ ...styles.unit, '&:before': { border: 'none' }, '&:after': { border: 'none' }, '&:not(.Mui-disabled):hover::before': { border: 'none' }, '.MuiSelect-icon': { display: 'none' }, '.MuiSelect-select': { padding: '0 !important', width: 50 } }}
          variant="standard" value={unit} onChange={(event) => onChange('unit', event.target.value)}
        >
          {completeUnitsWithPortion.map(item => <MenuItem key={uniqueId + item.code} value={item}>{t(item.code)}</MenuItem>)}
        </Select>
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 250, color: 'darkred' }} value={ingredient} onChange={event => onChange('name', event.target.value)} onBlur={event => onBlur('name', ingredient)} />
      </td>
      <td style={styles.td}>
        <input style={styles.checkbox} type="checkbox" checked={isAssoChecked} onChange={event => onChangeAssoChecked(event.target.checked)} onBlur={event => onBlur('assoChecked', isAssoChecked)} />
      </td>
      <td style={styles.td}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <input style={{ ...styles.customTextField, width: 250, borderBottom: hoverAssociation ? 'solid 2px black' : 'solid 1px rgba(0,0,0,0.42)' }}
            value={associationNameInput}
            onFocus={() => setIsAutoCompleteFocused(true)}
            onChange={(event) => onChange('associationInput', event.target.value)}
            onBlur={event => onBlur('associationInput', associationNameInput)}
          //onMouseEnter={handleMouseEnter}
          //onMouseLeave={handleMouseLeave} 
          />
          <div style={{ position: 'relative' }}>
            <div style={styles.autocomplete}>
              {isAutoCompleteFocused && associationNameInput.length > 1 ? renderAutocomplete() : null}
            </div>
          </div>
        </div>
      </td>
      <td style={styles.td} onClick={() => setIsAutoCompleteFocused(false)}>
        <div className='associationId'>{associationNameObject.id != null ? associationNameObject.id : ''}</div>
      </td>
      <td style={styles.td} onClick={() => setIsAutoCompleteFocused(false)}>
        <div style={{ backgroundColor: (associationNameObject.owner == null || associationNameObject.owner === 'ERN') ? 'transparent' : '#ffb340' }}>{associationNameObject.owner ? associationNameObject.owner : ''}</div>
      </td>
      <td style={styles.td} onClick={() => setIsAutoCompleteFocused(false)}>
        <div style={{ backgroundColor: (associationNameObject.id == null || isUnitCompatible) ? 'transparent' : '#ed5f5f' }}>{associationNameObject.id == null ? null : (isUnitCompatible ? 'y' : 'n')}</div>
      </td>
      <td style={styles.td} onClick={() => setIsAutoCompleteFocused(false)}>
        <div styles={{ color: colors.textLight }} >{which}</div>
      </td>
      <td>
        <ButtonFull text={t('C')} onButtonClick={() => duplicateIngredient()} color={buttonColor} disabled={((associationNameObject.owner === null || associationNameObject.owner === 'ERN') && which != '7') || (yieldAssoKg == null && yieldAssoL == null && yieldAssoUnit == null && yieldAssoPortion === 0)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 140 }} value={clarification} onChange={event => onChange('clar', event.target.value)} onBlur={event => onBlur('clar', clarification)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 140 }} value={alternative} onChange={event => onChange('alt', event.target.value)} onBlur={event => onBlur('alt', alternative)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 140 }} value={step} onChange={event => onChange('step', event.target.value)} onBlur={event => onBlur('step', step)} />
      </td>
      <td style={styles.td}>
        <input style={{ ...styles.customTextField, width: 600 }} value={rawIngredient} onChange={event => onChange('all', event.target.value)} onBlur={event => onBlur('all', rawIngredient)} />
      </td>
    </tr >
  );
};

const styles = {
  row: {
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  td: {
    paddingRight: 20
  },
  checkbox: {
    width: 18,
    height: 18,
    accentColor: colors.main,
  },
  autocomplete: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 200,
    overflowY: 'scroll',
    backgroundColor: colors.veryLightGrey,
    zIndex: 20
  },
  customTextField: {
    padding: '4px 0 5px 0',
    fontFamily: 'Inter',
    fontSize: 16,
    border: 'none',
    transition: 'border-bottom 50ms cubic-bezier(0.4, 0, 0.2, 1)',
    outline: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.42)',
    backgroundColor: 'transparent'
  },
  unit: {
    fontFamily: 'Inter',
    width: 50,
    fontSize: 15,
    fontWeight: 600,
    color: colors.black,
    textAlign: 'center',
    borderRadius: 1.5,
    borderStyle: 'solid',
    borderColor: colors.grey,
    borderWidth: 1
  },
};
export default AssociationRow;
/*export default memo(AssociationRow, (oldProps, newProps) => {
  if (newProps.association.name === 'Sel') {
    console.log('Sel', newProps.association.qty, oldProps.association, newProps.association);
    //console.log(isEqual(oldProps.association, newProps.association), oldProps.autoComplete.length === newProps.autoComplete.length);
  }
  if (isEqual(oldProps.association, newProps.association) && oldProps.autoComplete.length === newProps.autoComplete.length) {
    return true;
  }
});*/