import React, { useState } from 'react';
//util
import { colors } from '../util/values';

export const ButtonFull = ({ text, color, disabled, isFakeDisabled, onButtonClick }) => {
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    if (!disabled || isFakeDisabled) {
      onButtonClick();
    }
  };

  return (
    <div
      style={disabled ? { ...styles.buttonFull, ...styles.buttonFullDisabled } : hover ? { ...styles.buttonFull, ...styles.buttonFullHover } : { ...styles.buttonFull, backgroundColor: color ? color : colors.main }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >{text}</div>
  );
};

export const ButtonEmpty = ({ text, onButtonClick }) => {
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    onButtonClick();
  };

  return (
    <div
      style={hover ? { ...styles.buttonEmpty, ...styles.buttonEmptyHover, padding: '5px 20px', } : { ...styles.buttonEmpty, padding: '5px 20px', }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >{text}</div>
  );
};

export const ButtonEmptyNoSpace = ({ text, onButtonClick }) => {
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    onButtonClick();
  };

  return (
    <div
      style={hover ? { ...styles.buttonEmpty, ...styles.buttonEmptyHover } : styles.buttonEmpty}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >{text}</div>
  );
};

const styles = {
  buttonFull: {
    alignSelf: 'center',
    padding: '5px 20px',
    color: colors.textNegative,
    borderRadius: 9,
    cursor: 'pointer',
    transition: 'background-color 0.1s linear'
  },
  buttonFullDisabled: {
    cursor: 'default',
    backgroundColor: colors.darkGray,
  },
  buttonFullHover: {
    backgroundColor: colors.darkGray,
  },
  buttonEmpty: {
    alignSelf: 'center',
    color: colors.darkGray,
    backgroundColor: null,
    cursor: 'pointer',
    transition: 'color 0.1s linear'
  },
  buttonEmptyHover: {
    color: colors.main,
  },
};