import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import i18n from './i18n';
import { UserProvider } from './context/UserContext';
import AppRoutes from './AppRoutes';
import { HashRouter } from "react-router-dom";

const App = () => {
  useEffect(() => {
    changeLang();
  }, []);

  const changeLang = async () => {
    let lang = localStorage.getItem("appLanguage");
    lang = lang ? lang : 'fr';
    i18n.changeLanguage(lang);
  }

  return (
    <HashRouter>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </HashRouter>
  );
};

export default App;
