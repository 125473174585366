import React, { useContext, useState } from 'react';
import { SlPeople } from "react-icons/sl";
import { FaCog, FaInfoCircle } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import { RiHeartAddFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//components
import { ButtonFull } from '../components/Buttons';
//context
import { logout } from "../context/AuthService";
//util
import { colors, optionsData } from '../util/values';
import { commonStyles } from '../util/styles';


const OptionsScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hover, setHover] = useState("");

  const handleMouseEnter = (option) => {
    setHover(option);
  };

  const handleMouseLeave = () => {
    setHover("");
  };

  const onLogout = () => {
    logout();
    document.location.reload();
  };

  const renderIcon = (iconName) => {
    if (iconName === "cog") {
      return <FaCog size="2em" color={colors.main} style={styles.itemIcon} />;
    } else if (iconName === "people") {
      return <SlPeople size="2em" color={colors.main} style={styles.itemIcon} />;
    } else if (iconName === "locked") {
      return <IoIosLock size="2em" color={colors.main} style={styles.itemIcon} />;
    } else if (iconName === "heart-plus") {
      return <RiHeartAddFill size="2em" color={colors.main} style={styles.itemIcon} />;
    } else if (iconName === "info-circle") {
      return <FaInfoCircle size="2em" color={colors.main} style={styles.itemIcon} />;
    }
  };

  const openLink = (link) => {
    window.open(link, '_blank', 'noopener, noreferrer');
  };

  const optionList = (itemList) => {
    return (
      <div style={styles.page}>{
        itemList.map((item) => {
          return (
            <div key={item.nav} style={{ ...styles.itemContainer, backgroundColor: hover === item.nav ? colors.semiLightGrey : colors.lightGrey, transition: 'background-color 0.1s ease-out' }}
              onClick={() => navigate(item.nav, { state: { title: item.title, icon: item.iconName } })}
              onMouseEnter={() => handleMouseEnter(item.nav.toString())}
              onMouseLeave={handleMouseLeave}>
              {renderIcon(item.iconName)}
              <div style={styles.itemText}>{t(item.title)}</div>
            </div>)
        })
      }</div>
    );
  }

  return (
    <div style={commonStyles.container}>
      <div style={commonStyles.title}>{t('routeAccount')}</div>
      <div style={commonStyles.body}>
        <div style={styles.infoContainer}>
          <ButtonFull text={t("btnLogout")} onButtonClick={() => onLogout()} />
          <div style={{ marginTop: 20, marginBottom: 20, maxWidth: 500 }}>
            <div style={{ textAlign: "center", fontSize: 22, color: colors.main, marginTop: 5 }}>{t("text5")}</div>
            <div style={{ paddingHorizontal: 15, textAlign: "center", color: colors.text, fontSize: 14, paddingleft: 10, paddingRight: 10 }}>{t("text6")}</div>
            <div style={{ paddingHorizontal: 15, textAlign: "center", color: colors.text, fontSize: 14 }}>
              {t("text7")} <div style={{ color: colors.link, cursor: 'pointer' }} onClick={() => openLink('mailto:info@emissionsreductionnow.com')} onMouseEnter={() => handleMouseEnter("openMailTo")} onMouseLeave={handleMouseLeave}>info@emissionsreductionnow.com</div>
            </div>
            <div style={{ paddingHorizontal: 15, textAlign: "center", color: colors.text, fontSize: 14 }}>
              {t("text64")} <div style={{ color: colors.link, cursor: 'pointer' }} onClick={() => openLink('https://www.emissionsreductionnow.com')} onMouseEnter={() => handleMouseEnter("openWebsite")} onMouseLeave={handleMouseLeave}>www.emissionsreductionnow.com</div>
            </div>
          </div>
        </div>
        {optionList(optionsData)}
      </div>
    </div>
  );
};

const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch"
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    backgroundColor: colors.background
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    padding: '15px 20px',
    cursor: 'pointer'
  },
  itemIcon: {
    marginRight: 20
  },
  itemText: {
    fontSize: 20,
    color: colors.text
  },
};

export default OptionsScreen;
