import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { writeFileXLSX, utils } from 'xlsx';
import ReactLoading from 'react-loading';
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { ButtonFull } from '../components/Buttons';
import { ButtonOption } from '../components/ToolBarOption';
import { Graph } from '../components/Graph';
//util
import { colors, colorValuesRecipes } from '../util/values';
import { commonStyles } from '../util/styles';
import { calculateGraph } from '../util/climatecook';

const StatsOfferScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [categories, setCategories] = useState([""]);
  const [menus, setMenus] = useState([""]);
  const [data, setData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    setTimeout(() => setIsReady(true), 1000);
  }, [categories]);

  const getStats = async () => {
    setIsReady(false);
    const response = await aws.get('/stats', { params: { type: "offer", userId: user[0].user_id } });

    if (response.data) {
      let newCategories = ['offer'];
      let newMenus = [];
      for (let item of response.data) {
        const catName = i18n.language === 'fr' ? item.cat_name_fr : item.cat_name_en;
        if (catName !== null && !newCategories.includes(catName)) {
          newCategories.push(catName);
        }

        let menusArray = [];
        if (item.menus === '') {
          menusArray = ['null'];
          if (!newMenus.includes('null')) {
            newMenus = ['null', ...newMenus];
          }
        } else {
          let recipeMenus = item.menus.split(";;");
          menusArray = recipeMenus;
          for (let m of recipeMenus) {
            if (!newMenus.includes(m)) {
              newMenus.push(m);
            }
          }
        }
        item.menusArray = menusArray;
      }
      setData(response.data);
      setCategories(newCategories);
      setMenus(newMenus);
    }

    //setIsReady(true);
  };

  const onExport = () => {
    let rows = [];
    for (let menu of menus) {
      for (let category of categories) {
        let result = calculateGraph(data, menu, category, false);
        rows.push({
          [t('lblMenu')]: result.menu !== 'null' ? result.menu : t('lblNullMenus'),
          [t('lblCategory')]: result.category !== 'offer' ? result.category : t('lblOffer'),
          ['KgCo2e/Portion']: result.impact.toFixed(3),
          [t('lblGreen')]: result.thresholdLow.toFixed(3),
          [t('lblYellow')]: result.thresholdHigh.toFixed(3)
        });
      }
    }
    const worksheet = utils.json_to_sheet(rows);

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, t('routeStatsOffer'));

    writeFileXLSX(workbook, t('routeStatsOffer') + ".xlsx", { bookType: "xlsx", compression: true });
  };

  return (
    <div style={commonStyles.container}>
      <div style={commonStyles.title}>{t('routeStatsOffer')}</div>
      <div style={{ ...commonStyles.body, maxWidth: 'auto' }}>
        <div style={styles.otherContainer}>
          <div style={{ marginRight: 'auto' }}>
            <ButtonOption text={t("lblExport")} onButtonClick={() => onExport()} />
          </div>
          {!isReady ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> :
            <div style={{ ...styles.graphContainer, visibility: isReady ? 'visible' : 'hidden' }}>
              <Graph key="graph1" data={data} categories={categories} menus={menus} />
              <Graph key="graph2" data={data} categories={categories} menus={menus} />
              <Graph key="graph3" data={data} categories={categories} menus={menus} />
            </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  otherContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    marginTop: 80,
  },
  graphContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: 50
  },
};

export default StatsOfferScreen;