import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
//util
import { colors } from '../util/values';

export const Picker = ({ list, ingredientCo2, onItemPicked }) => {
  const { t } = useTranslation();
  const [itemList, setItemList] = useState([]);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setItemList(list);
  }, [list]);

  const openPicker = () => {
    setIsOpened(!isOpened);
  };

  const handleClick = (item) => {
    setIsOpened(false);
    onItemPicked(item);
  };

  const renderItem = (item, index) => {
    if (!isOpened && index > 0)
      return null;

    return (
      <tr key={"picker" + index} onClick={() => itemList.length === 1 || isOpened ? handleClick(item) : null}>
        <td style={{ padding: '2px 30px 2px 0' }}>{item.name}</td>
        <td style={{}}>{((ingredientCo2 - item.co2) * 100 / ingredientCo2).toFixed(0)}% {t("lblBetter")}</td>
      </tr>
    );
  };

  return (
    <div style={styles.container} onClick={() => itemList.length >= 1 ? openPicker() : null}>
      <div style={{ display: 'flex', alignItems: 'center', }} onClick={() => itemList.length >= 1 ? openPicker() : null}>
        <div style={{ marginRight: 20 }}>{t("lblAlternative")} </div>
        <table style={{ width: '100%' }}>
          <tbody>
            {itemList.map((item, index) => renderItem(item, index))}
          </tbody>
        </table>
      </div>
      {itemList.length > 1 && !isOpened ? <div style={{ marginLeft: 114 }}>+</div> : null}
    </div>

  );
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 700,
    color: colors.main,
    cursor: 'pointer'
  }
};